import React, { useState, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import ServeServices from "../../../ServeServices";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import Pagination from "./Pagination/Pagination";
import './DataTableDecline.scss'

export default function DataTableDecline(props) {
  const [selectedOption, setSelectedOption] = useState(null)
  const [manufacturers, setManufacturers] = useState(null)
  const [optionSelect, setOptionSelect] = useState("Todas")
  const [declinedOpportunities, setDeclinedOpportunities] = useState(null)
  const [declinedQueueOpportunities, setDeclinedQueueOpportunities] = useState(null)
  const [uncreatedOpportunities, setUncreatedOpportunities] = useState(null)
  const [allOpportunities, setAllOpportunities] = useState(props.opportunities?.sort((a, b) => {
    if (a.index > b.index) {
      return true
    } else {
      return -1
    }
  }))
  const [opportunities, setOpportunities] = useState(props.opportunities?.sort((a, b) => {
    if (a.index > b.index) {
      return true
    } else {
      return -1
    }
  }))

  let dataTableDecline;
  let tableBody;
  let paginationDataTableDecline;
  let filterContentDecline;
  const options = [10, 15, 20, 25, 30, 50, 100];
  const [selectedPage, setSelectedPage] = useState(0);
  const [itemsShow, setItemsShow] = useState((localStorage.getItem("itemsShow") === null) ? 0 : localStorage.getItem("itemsShow"));
  const sliceIndex = selectedPage * options[parseInt(itemsShow)];
  let numberOfPages;
  const requiredPages = opportunities.length / options[parseInt(itemsShow)];
  let dataToShow;
  if (props.opportunities) {
    dataToShow = opportunities.slice(
      sliceIndex,
      sliceIndex + options[parseInt(itemsShow)]
    );
  }

  useEffect(() => {
    let _manufacturers = []
    let manufacturersData = []
    props.opportunities.map(opportunity => {
      Object.keys(opportunity.matches).forEach(key => {
        if (!_manufacturers.includes(key)) {
          _manufacturers.push(key)
        }
      })
    })
    _manufacturers.sort((a, b) => {
      if (a > b) {
        return true
      } else {
        return -1
      }
    })
    _manufacturers.map(key => {
      manufacturersData.push({ "value": key, "label": key, "isDisabled": false })
    })
    setManufacturers(manufacturersData)
  }, [])

  useEffect(() => {
    if (optionSelect === "Declinada") {
      setOpportunities(declinedOpportunities)
    } else if (optionSelect === "Não criada") {
      setOpportunities(uncreatedOpportunities)
    } else if (optionSelect === "Todas") {
      setOpportunities(allOpportunities)
    } else if (optionSelect === "fila") {
      setOpportunities(declinedQueueOpportunities)
    } else if (optionSelect === null) {
      setOpportunities(allOpportunities)
    }
  }, [optionSelect])

  if (requiredPages % 1 !== 0) {
    if (requiredPages % 1 >= 5) {
      numberOfPages = Math.floor(requiredPages);
    } else {
      numberOfPages = Math.floor(requiredPages) + 1;
    }
  } else {
    numberOfPages = requiredPages;
  }

  const onPageChanged = (index) => {
    if (index < 1) {
      index = 0;
    } else if (index >= numberOfPages - 1) {
      index = numberOfPages - 1;
    }
    setSelectedPage(index);
  };

  const onItemsShowChanged = (index) => {
    localStorage.setItem("itemsShow", index);
    setItemsShow(index);
    setSelectedPage(0);
  };

  const DownloadFileAsync = async (filename, fileid) => {
    const link = await ServeServices.getOpportunityFile(props.user, filename, fileid);

    var a = document.createElement('a');
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link.data.link;
    a.target = "_blank";
    a.download = filename;
    a.click();
  }

  const downloadTable = (o) => {
    let formData = o.map(item => {

      let _dateStart = new Date(item.dateStart * 1000)
      let _dateEnd = new Date(item.dateEnd * 1000)
      return {
        "Número": item.index,
        "Descrição Obj. Contratação": item.description,
        "Data Início": _dateStart,
        "Data Fim": _dateEnd,
        "Origem": item.origin,
        "Status": item.status,
      }
    })

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(formData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Histórico" + fileExtension);
  }

  function filterOpportunityToManufacturer(manufacturer) {
    let data;
    if (optionSelect === "Declinada") {
      data = declinedOpportunities
    } else if (optionSelect === "Não criada") {
      data = uncreatedOpportunities
    } else if (optionSelect === "Todas") {
      data = allOpportunities
    } else if (optionSelect === "fila") {
      data = declinedQueueOpportunities
    } else {
      data = opportunities
    }
    let _opportunities = []
    data.map((opportunity) => {
      Object.keys(opportunity.matches).forEach(key => {
        if (key === manufacturer) {
          _opportunities.push(opportunity)
        }
      })
    })
    setOpportunities(_opportunities)
  }

  function filterOpportunityToType(type) {
    setSelectedOption(null)
    let newOpportunities = []
    if (type === "Não criada") {
      props.opportunities.map(opportunity => {
        if (opportunity.status === type && opportunity.declined === false) {
          newOpportunities.push(opportunity)
        }
      })
      //atualizando as oportunidades
      setUncreatedOpportunities(newOpportunities)
    } else if (type === "Declinada") {
      props.opportunities.map(opportunity => {
        if (opportunity.status === "Cotação apresentada" && opportunity.declined === true) {
          newOpportunities.push(opportunity)
        }
      })
      //atualizando as oportunidades
      setDeclinedOpportunities(newOpportunities)
    } else if (type === "Todas") {
      newOpportunities = props.opportunities?.sort((a, b) => {
        if (a.index > b.index) {
          return true
        } else {
          return -1
        }
      })
      //atualizando as oportunidades
      setAllOpportunities(newOpportunities)
    } else if (type == 'fila') {
      props.opportunities.map(opportunity => {
        if (opportunity.declinedQueue === true) {
          newOpportunities.push(opportunity)
        }
      })
      setDeclinedQueueOpportunities(newOpportunities)
    }

    //atualizando o select
    let _manufacturers = []
    let manufacturersData = []
    newOpportunities.map(opportunity => {
      Object.keys(opportunity.matches).forEach(key => {
        if (!_manufacturers.includes(key)) {
          _manufacturers.push(key)
        }
      })
    })
    _manufacturers.sort((a, b) => {
      if (a > b) {
        return true
      } else {
        return -1
      }
    })
    _manufacturers.map(key => {
      manufacturersData.push({ "value": key, "label": key, "isDisabled": false })
    })
    setManufacturers(manufacturersData)


    //resetando o select

    //salvando o radio selecionado
    setOptionSelect(type)
  }

  if (opportunities.length !== undefined) {
    tableBody = dataToShow.map((opportunities, index) => {

      let _dateStart = new Date(opportunities.dateStart * 1000)
      let _dateEnd = new Date(opportunities.dateEnd * 1000)

      const rowClass = index % 2 === 0 ? "datatable-row-even" : "datatable-row-odd";

      return (
        <tr className={"datatable-row " + rowClass} key={`line: ${index}`}>
          <th className={"datatable-row-col"}>
            {opportunities.index}
          </th>
          <th className={"datatable-row-col "}>
            {opportunities.description}
          </th>
          <th className={"datatable-row-col "}>
            {_dateStart.toLocaleDateString("pt-BR")}
          </th>
          <th className={"datatable-row-col "}>
            {_dateEnd.toLocaleDateString("pt-BR")}
          </th>
          <th className={"datatable-row-col "}>
            {opportunities.qtdItems}
          </th>
          <th className={"datatable-row-col "}>
            <FontAwesomeIcon size="lg" icon={faFileDownload} onClick={async () => { opportunities.files.split(',').map(obj => { DownloadFileAsync(obj, opportunities.fileId) }) }} />
          </th>
          <th className={"datatable-row-col "}>
            {opportunities.origin}
          </th>
        </tr>
      )
    })
  } else {
    let _dateStart = new Date(opportunities.dateStart * 1000)
    let _dateEnd = new Date(opportunities.dateEnd * 1000)

    tableBody = (
      <tr className={"datatable-row datatable-row-odd"} >
        <th className={"datatable-row-col"}>
          {opportunities.index}
        </th>
        <th className={"datatable-row-col "}>
          {opportunities.description}
        </th>
        <th className={"datatable-row-col "}>
          {_dateStart.toLocaleDateString("pt-BR")}
        </th>
        <th className={"datatable-row-col "}>
          {_dateEnd.toLocaleDateString("pt-BR")}
        </th>
        <th className={"datatable-row-col "}>
          {opportunities.qtdItems}
        </th>
        <th className={"datatable-row-col "}>
          <FontAwesomeIcon size="lg" icon={faFileDownload} onClick={async () => { opportunities.files.split(',').map(obj => { DownloadFileAsync(obj, opportunities.fileId) }) }} />
        </th>
        <th className={"datatable-row-col "}>
          {opportunities.origin}
        </th>
      </tr>
    )
  }

  if (props.opportunities) {
    dataTableDecline = (
      <table className="w-100 datable-main">
        <thead>
          <tr className="datable-head-row">
            <th className="datatable-header-col">
              <div className="d-flex flex-row">
                <p>
                  Número
                </p>
                <i className={"arrow-up ml-2 "}></i>
              </div>
            </th>
            <th className="datatable-header-col">
              <div className="d-flex flex-row">
                <p>
                  Desc. do Objeto
                </p>
                <i className={"arrow-up ml-2 "}></i>
              </div>
            </th>
            <th className="datatable-header-col">
              <div className="d-flex flex-row">
                <p>
                  Dt início
                </p>
                <i className={"arrow-up ml-2 "}></i>
              </div>
            </th>
            <th className="datatable-header-col">
              <div className="d-flex flex-row">
                <p>
                  Dt Fim
                </p>
                <i className={"arrow-up ml-2 "}></i>
              </div>
            </th>
            <th className="datatable-header-col">
              <div className="d-flex flex-row">
                <p>
                  Itens
                </p>
                <i className={"arrow-up ml-2 "}></i>
              </div>
            </th>
            <th className="datatable-header-col">
              <div className="d-flex flex-row">
                <p>
                  Anexos
                </p>
                <i className={"arrow-up ml-2 "}></i>
              </div>
            </th>
            <th className="datatable-header-col">
              <div className="d-flex flex-row">
                <p>
                  Origem
                </p>
                <i className={"arrow-up ml-2 "}></i>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableBody}
        </tbody>
      </table>
    )
  }

  if (props.opportunities) {
    paginationDataTableDecline = (
      <Col className="d-flex flex-row justify-content-end align-content-center mt-3 mr-3">
        <Pagination
          title="Oportunidades"
          itens={opportunities.length}
          options={options}
          selectedPage={selectedPage}
          selectedOption={parseInt(itemsShow)}
          onPageChanged={onPageChanged}
          onItemsShowChanged={onItemsShowChanged}
          pagesNumber={numberOfPages}
          downloadTable={() => { downloadTable(opportunities) }}
          dataToShow={dataToShow}
        />
      </Col>
    )
  }

  return (
    <>
      <Row className="w-100 container-filters">
        <div className="radio-filter">
          <input
            className="ml-4 mr-1"
            checked={optionSelect === "Todas" ? true : false}
            type="radio"
            id="Todas"
            name="option"
            onChange={(e) => filterOpportunityToType(e.target.id)}
          />
          <label >Todas</label>
          <input
            className="ml-4 mr-1"
            type="radio"
            id="Declinada"
            name="option"
            onChange={(e) => filterOpportunityToType(e.target.id)}
          />
          <label >Declinadas</label>

          <input
            className="ml-4 mr-1"
            type="radio"
            id="Não criada"
            name="option"
            onChange={(e) => filterOpportunityToType(e.target.id)}
          />
          <label >Não criadas</label>
        </div>

        {filterContentDecline}

      </Row>
      <Row className="w-100">

        <Col xs={12} className="w-100 datatable-outer">
          {dataTableDecline}
        </Col>
        {paginationDataTableDecline}
      </Row>
    </>
  )
}