import React, { useState, useEffect } from "react";
import "./Login.scss";
import { Row, Col, Form, Button, Alert, NavLink } from "react-bootstrap";
import FormCard from "../../Cards/FormCard/FormCard";
import { connect } from "react-redux";
import * as storeActions from "../../../store/actions/index";
import LoadingComponent from "../../Loading/Spinner";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import publicIp from "react-public-ip";

async function ip() {
  return {
    "v4": "", // await publicIp.v4() || "",
    "v6": "" //await publicIp.v6() || 
  };
}

function LoginModal(props) {
  const baseURL = window.location.href;

  const [userEmail, setUserEmail] = useState("")
  const [userPassword, setUserPassword] = useState("")
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Habilite o GPS do seu navegador para realizar o login");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [onLogin, setLogin] = useState(false);
  const [statusCaptcha, setStatusCaptcha] = useState(true); // Se estiver sob domínio = TRUE; Se estiver local = FALSE 

  async function handleLogin(coords) {
    setLogin(false);
    let usuario;

    try {
      await props.onLoginSubmitted({ email: userEmail, password: userPassword }).then(el => { usuario = el });
      if (usuario) {
        [0].map(async _ => {
          usuario["ip"] = await ip();
          usuario["latitude"] = coords.latitude;
          usuario["longitude"] = coords.longitude;
          usuario["accuracy"] = coords.accuracy;
          await props.fetchData(usuario);
        })
      }
    } catch (error) {
      console.log("error");
    }
  }

  function handleVerificationSuccess(token, ekey) {
    setStatusCaptcha(false)
  }

  useEffect(() => {
    if (onLogin) {
      setLogin(false);

      // setErrorMessage("Falha da API do Google Chrome");
      // console.log(error.message);
      setShowAlert(false);
      handleLogin({
        coords: {
          latitude: 0,
          longitude: 0,
          accuracy: 0
        }
      });

      // navigator.geolocation.getCurrentPosition((position) => {
      //   // console.log(latitude, longitude);
      //   setLatitude(position.coords.latitude);
      //   setLongitude(position.coords.longitude);

      //   if (latitude === 0 && longitude === 0) {
      //     setShowAlert(true);
      //     setLogin(true);
      //   } else {
      //     setShowAlert(false);
      //     setLogin(false);
      //     handleLogin(position.coords);
      //   }
      // }, (error) => {
      //   switch (error.code) {
      //     case error.PERMISSION_DENIED:
      //       setErrorMessage("Usuário rejeitou a solicitação de Geolocalização.");
      //       setShowAlert(true);
      //       break;
      //     case error.POSITION_UNAVAILABLE:
      //       if (error.message === "Network location provider at 'https://www.googleapis.com/' : No response received.") {
      //         setErrorMessage("Falha da API do Google Chrome");
      //         // console.log(error.message);
      //         setShowAlert(false);
      //         setLogin(false);
      //         handleLogin({
      //           coords: {
      //             latitude: 0,
      //             longitude: 0,
      //             accuracy: 0
      //           }
      //         });
      //       } else {
      //         setErrorMessage("Falha da API do Google Chrome");
      //         // console.log(error.message);
      //         setShowAlert(false);
      //         setLogin(false);
      //         handleLogin({
      //           coords: {
      //             latitude: 0,
      //             longitude: 0,
      //             accuracy: 0
      //           }
      //         });
      //       }
      //       setShowAlert(true);
      //       break;
      //     case error.TIMEOUT:
      //       setErrorMessage("A requisição expirou. Tente novamente.");
      //       setShowAlert(true);
      //       break;
      //     case error.UNKNOWN_ERROR:
      //       setErrorMessage("Algum erro desconhecido aconteceu.");
      //       setShowAlert(true);
      //       break;
      //     default:
      //       setErrorMessage("Falha da API do Google Chrome");
      //       // console.log(error.message);
      //       setShowAlert(false);
      //       setLogin(false);
      //       handleLogin({
      //         coords: {
      //           latitude: 0,
      //           longitude: 0,
      //           accuracy: 0
      //         }
      //       });
      //   }
      // });
    }
  });

  const submitLogin = (e) => {
    e.preventDefault();
    setLogin(true);
    setShowAlert(true);
  };

  let loginContent;
  if (props.Userloading) {
    loginContent = <LoadingComponent title="Realizando o login..." />;
  } else if (props.loading) {
    loginContent = <LoadingComponent title="Buscando as informações..." />;
  } else {
    loginContent = (
      <Col className="container-input-login">
        <Row className="h-50">
          <Col xs={12}>
            <Form onSubmit={async (e) => await submitLogin(e)}>
              <Form.Group>
                <Form.Label>Usuário</Form.Label>
                <Form.Control
                  type="text"
                  className="login-input-control"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  type="password"
                  className="login-input-control"
                  value={userPassword}
                  onChange={(e) => setUserPassword(e.target.value)}
                />
              </Form.Group>
              {showAlert === true ?
                <div className="alert-gps">
                  <Alert variant="warning" onClose={() => setShowAlert(false)} dismissible>
                    {errorMessage}
                  </Alert>
                </div>
                :
                statusCaptcha === true ?
                  <>
                    <p className="legend-captcha">Complete o captcha para entrar</p>
                    <Col xs={12} className="content-captcha">
                      <HCaptcha
                        sitekey="dfc29da1-236a-4160-8057-e51409ede601"
                        onVerify={(token, ekey) => handleVerificationSuccess(token, ekey)}
                      />
                    </Col>
                  </>
                  :
                  <Button
                    variant="primary"
                    type="submit"
                    className="button-login"
                  >
                    Entrar
                  </Button>
              }
            </Form>
          </Col>
        </Row>

        {/* ---------------------------------------------------
 *          FUNCIONALIDADE DESATIVADA PARA UM ESTUDO FUTURO
 * 
 *            Cadastro de novos usuários
 *       
        ------------------------------------------------------*/}
        {(statusCaptcha === false) ?
          <Row className="mt-5 justify-content-start">

            {/* {(true) ? <Col xs={12}>
          <p className="mb-0 login-nav-text">
            <br></br>
            <span className="login-title">Usuário novo? </span>
            <a href={"/signup"}>Crie uma conta.</a>
          </p>
        </Col> : null} */}

            <Col xs={12}>
              <p className="mb-0 login-nav-text">
                <br></br>
                <a href="/passwordforget">Esqueci minha senha</a>
              </p>
            </Col>
          </Row>
          : <></>}

      </Col>
    );
  }

  return (
    <FormCard>
      {loginContent}
    </FormCard>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    Userloading: state.auth.loading,
    loading: state.control.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoginSubmitted: (userData) =>
      dispatch(storeActions.authenticate(userData)),

    fetchData: (token) => dispatch(storeActions.fetchData(token)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);