import React, { useState, useEffect } from 'react';
import { Card, Row, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";


export default function OpportunityStatus(props) {
        const [statusList, setStatusList] = useState([]);
        const [selectedStatusList, setSelectedStatusList] = useState({});
        const [selectedLocalStatus, setSelectedLocalStatus] = useState({});
        const [note, setNote] = useState("");
        const [oppStatusId, setOppStatusId] = useState(null)
        const [success, setSuccess] = useState(false);
        const [isEdited, setIsEdited] = useState(false);
        const [needsDeclinate, setNeedsDeclinate] = useState(false);
        const [declineStatus, setDeclineStatus] = useState({});
        const [actionSelected, setActionSelected] = useState(false)
        const [justificationMessage, setJustificationMessage] = useState("Nós agradecemos, mas declinamos desta oportunidade")

        async function getOpStatus() {
                if (props.modalStatusOpportunity) {
                        setActionSelected(props.modalStatusOpportunity.origin === "Minhas Participações")
                        props.initOneOpportunityStatus(props.user, props.modalStatusOpportunity._id).then((value) => {
                                // console.log(value)
                                if (value.statusListId) {
                                        setSelectedLocalStatus(value);
                                        setSelectedStatusList(statusList.find(o => o._id === value.statusListId));
                                        setNote(value.note);
                                        setIsEdited(true);
                                }
                        })
                }
        }

        useEffect(() => {
                if (props.modalStatusOpportunity) {
                        if (props.modalStatusOpportunity.localStatus.length === 1 && isEdited === false) {
                                setSelectedLocalStatus(props.modalStatusOpportunity.localStatus[0]);
                                setSelectedStatusList(props.modalStatusOpportunity.localStatus[0].statusName);
                                setNote(props.modalStatusOpportunity.localStatus[0].note);
                                setOppStatusId(props.modalStatusOpportunity.localStatus[0]._id)
                        }
                }
        }, [note === "" && selectedStatusList && props.modalStatusOpportunity !== null]);

        useEffect(() => {
                props.statusList(props.user).then(value => {
                        setStatusList(value);
                        getOpStatus();
                })
        }, [selectedLocalStatus && props.modalStatusOpportunity !== null]);

        async function createStatus() {
                if(props.user.data?.presentation === true){
                        alert("No modo apresentação, não é possível fazer esta ação.");
                
                }else if (!!selectedStatusList?._id) {
                        if (success === false) {
                                let dateEndStr = props.modalStatusOpportunity.dateEnd.split(" ");
                                let dateEndStrFormatted = dateEndStr[0].split("/")[2] + "-" + dateEndStr[0].split("/")[1] + "-" + dateEndStr[0].split("/")[0] + " " + dateEndStr[1]
                                let dateEnd = new Date(dateEndStrFormatted);

                                await props.setOpportunityStatus(props.user, {
                                        "_id": oppStatusId,
                                        "index": props.modalStatusOpportunity.index.props.children,
                                        "note": note,
                                        "dateEnd": dateEnd.getTime() / 1000,
                                        "statusListId": selectedStatusList._id,
                                        "userId": props.user.data._id,
                                        "opportunityId": props.modalStatusOpportunity._id,
                                        "portalId": props.modalStatusOpportunity.portalId,
                                        "companyId": props.user.data.companyId
                                });

                                let checkout = true;

                                if (needsDeclinate === true) {
                                        if(!!declineStatus._id){
                                                await props.postDeclineOpportunity({
                                                        "index": props.modalStatusOpportunity.index.props.children,
                                                        "reason": justificationMessage,
                                                        "declined": false,
                                                        "declineQueue": true,
                                                        "opportunityId": props.modalStatusOpportunity._id,
                                                        "dateEnd": dateEnd.getTime() / 1000,
                                                        "declineStatusId": declineStatus._id,
                                                        "companyId": props.user.data.companyId,
                                                        "portalId": props.modalStatusOpportunity.portalId,
                                                        "failed": false,
                                                }, props.user);
                                        }else{
                                                checkout = false
                                                alert("Selecione o motivo do declínio!");
                                        }
                                        
                                }
                                
                                if(checkout === true){
                                        setSuccess(true);
                                }
                                
                        } else {
                                alert("Selecione a ação!");
                        }
                }
        }

        function resetFields() {
                props.setModalStatusOpportunity(null);
                setStatusList([]);
                setSelectedStatusList({});
                setNote("");
                setOppStatusId(null);
                setSuccess(false);
                setIsEdited(false);
                setNeedsDeclinate(false);
        }

        // console.log(selectedStatusList)

        return (<Modal
                size="lg"
                aria-labelledby="contained-modal-opportunity-status"
                centered
                show={!!props.modalStatusOpportunity?.localStatus}
                onHide={() => { resetFields(); }}
        >
                <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-opportunity-status">
                                Status da Oportunidade: {selectedStatusList.name}
                        </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <div className="col-auto">
                                <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                                <div className="input-group-text">Status</div>
                                        </div>
                                        <select type="text" onChange={
                                                (e) => {
                                                        setSelectedStatusList(statusList.find(o => o._id === e.target.value));
                                                        
                                                        if(statusList.find(o => o._id === e.target.value)?.name === "A Declinar"){
                                                                setNeedsDeclinate(true)
                                                        }else{
                                                                setNeedsDeclinate(false)
                                                        }
                                                        // setIsEdited(true);
                                                        // console.log(statusList.find(o => o._id === e.target.value))
                                                }}

                                                value={selectedStatusList._id} className="form-control">
                                                <option disabled={true} selected={true}>Selecione a opção</option>
                                                {statusList.map(o => {
                                                        if(o.name === "A Declinar" && props?.modalStatusOpportunity?.origin !== "Minhas Participações"){
                                                          return (<option disabled={true} value={o._id}>{o.name}</option>)
                                                        }else{
                                                                return (<option value={o._id}>{o.name}</option>)
                                                        }
                                                
                                                })}
                                        </select>
                                </div>

                                <h4 className="mt-4">Anotação/Justificativa: </h4>
                                <textarea onChange={(e) => { setNote(e.target.value) }} class="form-control" value={note} rows="3"></textarea>
                                <hr />
                                <br />
                                <div style={needsDeclinate ? null : { display: 'none' }} >
                                        <h4 className="mt-2">Justificativa do declínio:</h4>
                                        <select onChange={(e) => { setDeclineStatus(props.declineStatus.find(probe => probe._id === e.target.value)) }} value={declineStatus._id} className="form-control">
                                                <option disabled={true} selected={true}>Selecione o motivo</option>
                                                {props.declineStatus.map((status) => {
                                                        return (<option value={status._id}>{status.description}</option>)
                                                })}
                                        </select>
                                        <br />
                                        <h3>Mensagem:</h3>
                                        <input type="text" value={justificationMessage} onChange={(e) => { setJustificationMessage(e.target.value) }} className="form-control" />
                                </div>
                                <div style={success ? null : { display: 'none' }} className="card mt-4">
                                        <div className="card-body">
                                                <h3 className="card-title">Dados Salvos!</h3>
                                        </div>
                                </div>
                        </div>
                </Modal.Body>
                <Modal.Footer className="d-flex p-3">
                        <div className="form-check mr-auto">
                                <input disabled={(success || !actionSelected)} type="checkbox" checked={needsDeclinate} onChange={() => { setNeedsDeclinate(!needsDeclinate) }} className="form-check-input" />
                                <label className="form-check-label">Habilitar declínio desta oportunidade</label>
                        </div>
                        <div className="">
                                <button type="button" disabled={success} onClick={() => createStatus()} className={needsDeclinate ? "btn btn-danger mr-2" : "btn btn-primary mr-2"}>Salvar Alterações {needsDeclinate ? " e Declinar" : ""}</button>
                                <button type="button" onClick={() => { resetFields() }} className="btn btn-secondary ">Fechar</button>
                        </div>
                </Modal.Footer>

        </Modal>)
}