import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn, faToggleOff, faTrash, faPlus, faHandPaper, faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { Card, Row, Col, ListGroup } from "react-bootstrap";
import { connect } from "react-redux";
import * as storeActions from "../../../../../store/actions/index";
import ModalControlKeys from "../ModalControlKeys";
import ModalControlCounterKeys from "../ModalControlCounterKeys";
import "./styles.scss"

function CategorySelectedList(props) {
  const [newItemCategory, setNewItemCategory] = useState(null);
  const [itemsCategory, setItemsCategory] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [showCounterModal, setShowCounterModal] = useState(null);
  const [allUsers, setAllUsers] = useState([])

  useEffect(async () => {
    loadingItemCategory();
  }, [props.category])

  useEffect(async () => {
    const totalUsers = await props.getUser(props.user)
    let validatedUsers = totalUsers.filter(probe => probe.disabled === false).map(obj => { return { label: obj.name, value: obj._id } })
    validatedUsers.unshift({ label: "Todos", value: "all" })
    setAllUsers(validatedUsers) // -> dados dos usuários 
  }, [allUsers.length === 0])

  async function loadingItemCategory() {
    let items = await props.initCategory(props.user);
    items = items.filter(obj => { if (obj.catalogId === props.category._id) return obj }).sort((a, b) => {
      if (a.name > b.name) {
        return true
      } else {
        return -1
      }
    })
    setItemsCategory(items);
  }

  async function saveNewItemCategory() {
    let data = {
      catalogId: props.category._id,
      companyId: props.category.companyId,
      disabled: false,
      important: false,
      importantColor: '#fed86d',
      name: newItemCategory,
      portalId: props.category.portalId,
      regex: [newItemCategory],
      target: props.category.name === "Itens por Fabricante" ? "Fabricante" : "item"
    };

    await props.postCategory(data, props.user.token);

    setNewItemCategory("");
    loadingItemCategory();
  }

  async function updateItem(item, status, flag) {

    item[flag] = status;
    // console.log(item)
    await props.putCategory(item, props.user);
    loadingItemCategory();
  }

  return (
    <Card className="cat-limiter">
      <Card.Body>
        <Card.Title>{props.category.name}</Card.Title>
        <Card.Text>
          <ListGroup variant="flush">
            <Card.Title className="mt-3">{"Adicionar em \"" + props.category.name + "\""}</Card.Title>
            <div className="input-group mb-3 mt-3">
              <input
                className="form-control"
                value={newItemCategory}
                onChange={(e) => { setNewItemCategory(e.target.value) }}
                type="text"
                placeholder="Item da Categoria"
              />
              <div className="input-group-append">
                <button
                  className="btn btn-flat"
                  onClick={saveNewItemCategory}
                >
                  <FontAwesomeIcon size="md" icon={faPlus} />
                </button>
              </div>
            </div>
            <Row className="p-3">
              <Col>Nome</Col>
              <Col>Palavras Chave</Col>
              <Col className="actions-icons">
                <Row>
                  <Col>
                    💧
                  </Col>
                  <Col>
                    ⚠️
                  </Col>
                  <Col>
                    ✅
                  </Col>
                  <Col>
                    ❌
                  </Col>
                </Row>
              </Col>
            </Row>

            <ListGroup.Item className="overflow-y">
              {itemsCategory?.map((catItem, indx) => {
                return (
                  <Row key={indx}>

                    <Col>
                      <input className="form-control" type="text" value={catItem.name} />
                    </Col>
                    <Col>
                      <button onClick={() => { setShowModal(catItem) }} className="btn btn-primary mr-2"><FontAwesomeIcon size="lg" icon={faPenAlt} /></button>
                      <button onClick={() => { setShowCounterModal(catItem) }} className="btn btn-danger "><FontAwesomeIcon size="lg" icon={faHandPaper} /></button>
                    </Col>
                    <Col>

                      <input className="p-0 m-0 btn" style={{ border: "none", width: 24, height: 24 }}
                        onChange={(event) => { updateItem(catItem, event.target.value, "importantColor") }}
                        type="color" id="head" name="head" value={catItem?.importantColor ? catItem.importantColor : "#fed86d"} />

                      <button

                        className={(catItem.important === true) ? "btn btn-circle text-warning" : "btn btn-circle text-danger"}
                        onClick={() => { updateItem(catItem, !catItem.important, "important") }}
                      >
                        <FontAwesomeIcon size="lg" icon={(catItem.important === true) ? faToggleOn : faToggleOff} />
                      </button>
                      <button
                        className={(catItem.disabled === false) ? "btn btn-circle text-flat" : "btn btn-circle text-danger"}
                        onClick={() => { updateItem(catItem, !catItem.disabled, "disabled") }}
                      >
                        <FontAwesomeIcon size="lg" icon={(catItem.disabled === false) ? faToggleOn : faToggleOff} />
                      </button>
                      <button
                        className="btn btn-circle text-danger"
                        onClick={async () => { await props.deleteCategory(catItem._id, props.user); loadingItemCategory(); }}
                      >
                        <FontAwesomeIcon size="lg" icon={faTrash} />
                      </button>
                    </Col>
                  </Row>
                )
              })}
            </ListGroup.Item>
          </ListGroup>
        </Card.Text>
      </Card.Body>
      {
        showModal &&
        <ModalControlKeys
          categoryItemSelected={showModal}
          closeModal={() => { setShowModal(null) }}
          category={props.category}
          categoriesList={props.categoriesList}
          putCategory={props.putCategory}
          user={props.user}
          allUsers={allUsers}
        />
      }
      {
        showCounterModal &&
        <ModalControlCounterKeys
          categoryItemSelected={showModal}
          closeCounterModal={() => { setShowCounterModal(null) }}
          category={showCounterModal}
          categoriesList={props.categoriesList}
          putCategory={props.putCategory}
          user={props.user}
        />
      }
    </Card >
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCategory: (user) => dispatch(storeActions.initCategory(user)),
    postCategory: (regex, token) => dispatch(storeActions.postCategory(regex, token)),
    putCategory: (regex, usuario) => dispatch(storeActions.putCategory(regex, usuario)),
    deleteCategory: (categoryId, token) => dispatch(storeActions.deleteCategory(categoryId, token)),
    getUser: (usuario) => dispatch(storeActions.getUser(usuario)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategorySelectedList);
