import React, { useState, useEffect } from "react";
import "./Fabricantes.scss";
import Card from "../../../Cards/Card";
import { Row, Col, DropdownButton, Dropdown, Container, InputGroup, FormControl, Button } from "react-bootstrap";
import { connect } from "react-redux";
import * as storeActions from "../../../../store/actions/index";
import SearchInput from "../../Ranking/OpportunityFilter/SearchInput/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faChartArea, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Select from 'react-select'
import RequestPieChart from '../../Ranking/Charts/RequestPieChart'
import RequestBarChart from "../../Ranking/Charts/RequestBarChart";
import RequestLineChart from "../../Ranking/Charts/RequestLineChart";
import RequestBarAndLineChart from "../../Ranking/Charts/RequestBarAndLineChart";
import RequestThreeLineChart from '../../MyAccount/charts/ThreeLineChart'
import ModalDownload from "./ModalDownload"

function Fabricantes(props) {
  const [loading, setLoading] = useState(false)
  const [loadingClickPie, setLoadingClickPie] = useState(false)
  const [loadingSelectMultiManufacturer, setLoadingSelectMultiManufacturer] = useState(false)
  const [loadingSelectSpecificMultiManufacturer, setLoadingSelectSpecificMultiManufacturer] = useState(false)
  const [listManufacturer, setListManufacturer] = useState([])
  const [formSearch, setFormSearch] = useState("Data Fim")
  const [originFetch, setOriginFetch] = useState("Minhas Participações")
  const [originList, setOriginList] = useState(["Minhas Participações", "Oportunidades Públicas"])
  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')
  const [mesSelecionado, setMesSelecionado] = useState('Janeiro')
  const [anoSelecionado, setAnoSelecionado] = useState('2021')
  const [trimestreSelecionado, setTrimestreSelecionado] = useState('1º Trimestre')
  const [semestreSelecionado, setSemestreSelecionado] = useState('1º Semestre')
  const [dashboard, setDashboard] = useState([])
  const [allOpportunities, setAllOpportunities] = useState([])
  const [equalsItems, setEqualsItems] = useState([])
  const [oportunidades, setOportunidades] = useState([])
  const [oportunidadeSelecionada, setOportunidadeSelecionada] = useState("")
  const [itemSelecionado, setItemSelecionado] = useState("")
  const [specificItemSelected, setSpecificItemSelected] = useState("")
  const [specificManufacturerSelected, setSpecificManufacturerSelected] = useState([])
  const [clickPie, setClickPie] = useState(false)
  const [statusDashboard, setStatusDashboard] = useState(false)
  const [legendManufacturer, setLegendManufacturer] = useState([])
  const [timeseriesManufacturer, setTimeseriesManufacturer] = useState({})
  const [firstLegendManufacturer, setFirstLegendManufacturer] = useState([])
  const [dataPie, setDataPie] = useState([])
  const [dataBar, setDataBar] = useState([])
  const [dataLine, setDataLine] = useState([])
  const [dataBarAndLine, setDataBarAndLine] = useState([])
  const [dataMultiLine, setDataMultiLine] = useState([])
  const [legend, setLegend] = useState(false)
  const [oportunidadeComProposta, setOportunidadeComProposta] = useState([])
  const [optionSelect, setOptionSelect] = useState([])
  const [propostaFabricanteItem, setPropostaFabricanteItem] = useState()
  const [dataSelectSpecific, setDataSelectSpecific] = useState([])
  const [dataSelectSpecificComparison, setDataSelectSpecificComparison] = useState([])
  const [opportunities, setOpportunities] = useState([])
  const [opportunitiesItemGeneric, setOpportunitiesItemGeneric] = useState()
  const [opportunitiesManufacturer, setOpportunitiesManufacturer] = useState()
  const [options, setOptions] = useState([])
  const [optionsSpecific, setOptionsSpecific] = useState([])
  const [proposalsManufacturers, setProposalsManufacturers] = useState([])
  const [quotationValueEmpresa, setQuotationValueEmpresa] = useState([])
  const [statusFirstCharts, setStatusFirstCharts] = useState(false)
  const [statusPieChart, setStatusPieChart] = useState(false)
  const [statusBarAndLineChart, setStatusBarAndLineChart] = useState(false)
  const [statusSelectSpecificMultiManufacturer, setStatusSelectSpecificMultiManufacturer] = useState(false)
  const [statusSelectSpecific, setStatusSelectSpecific] = useState(false)
  const [statusSelectOpportunity, setStatusSelectOpportunity] = useState(false)
  const [statusButtonManufacturers, setStatusButtonManufacturers] = useState(false)
  const [statusChartMultiLine, setStatusChartMultiLine] = useState(false)
  const [statusSelectCombined, setStatusSelectCombined] = useState(false)
  const [cotacoesApresentadaItemPieClick, setCotacoesApresentadaItemPieClick] = useState(null)
  const [cotacoesApresentadasItemEspecifico, setCotacoesApresentadasItemEspecifico] = useState(null)
  const [showModalDownload, setShowModalDownload] = useState(false)
  const [opportunityManufacturer, setOpportunityManufacturer] = useState(null)
  const [regexData, setRegexData] = useState({name: "Selecione a categoria"})

  //array que armazena os objetos com as legendas de cada fabricante selecionado
  let legendManufacturerArray = []
  let dataPieArray = []
  let itemsManufacturerSelected = []
  let itemsEqualsManufacturerSelected = []
  let proposalSelectedManufacturer = []
  let dataManufacturer = []

  /* Gerando anos automaticamente a partir de 2021 */
  let year = [];
  for (let i = 2021; i <= new Date().getFullYear(); i++) {
    year.push(i)
  }
  let currentCompany;
  props.companies.map(data => {
    if (data._id === props.user.data.companyId) {
      currentCompany = data.name
    }
  })
  let currentCompanyName = currentCompany.split(' ')[0]
  currentCompanyName = currentCompanyName[0].toUpperCase() + currentCompanyName.substr(1).toLocaleLowerCase()

  // controle de select máximo de fabricantes
  useEffect(() => {
    if (optionSelect !== undefined) {
      if (optionSelect.length < 3) {
        options.map((o) => {
          o.isDisabled = false
        })
      }
      else {
        options.map((o) => {
          o.isDisabled = true
        })
      }
    }
  }, [optionSelect])

  async function handleGetDashboard() {

    setLoadingSelectSpecificMultiManufacturer(false)
    setLoadingClickPie(false)
    setLoadingSelectMultiManufacturer(false)
    setDataMultiLine([])
    setListManufacturer([])
    itemsManufacturerSelected = []
    setDataBar([])
    setDataLine([])
    setDataPie([])
    setLegendManufacturer([])
    setFirstLegendManufacturer([])
    setStatusFirstCharts(false)
    setStatusSelectSpecificMultiManufacturer(false)
    setDataSelectSpecificComparison([])
    setLoading(true);
    setLegend(false);
    setStatusDashboard(false);
    setItemSelecionado("")
    setOportunidadeSelecionada("")
    setStatusSelectCombined(false)
    setStatusSelectSpecific(false)
    setStatusSelectOpportunity(false)
    setStatusBarAndLineChart(false)
    setStatusChartMultiLine(false)
    setSpecificManufacturerSelected([])
    setOptionsSpecific([])
    setSpecificManufacturerSelected([])
    setOptions([])
    setOptionSelect([])
    setStatusPieChart(false)

    let numMes = 0;
    switch (mesSelecionado) {
      case "Janeiro":
        numMes = 1;
        break;
      case "Fevereiro":
        numMes = 2;
        break;
      case "Março":
        numMes = 3;
        break;
      case "Abril":
        numMes = 4;
        break;
      case "Maio":
        numMes = 5;
        break;
      case "Junho":
        numMes = 6;
        break;
      case "Julho":
        numMes = 7;
        break;
      case "Agosto":
        numMes = 8;
        break;
      case "Setembro":
        numMes = 9;
        break;
      case "Outubro":
        numMes = 10;
        break;
      case "Novembro":
        numMes = 11;
        break;
      case "Dezembro":
        numMes = 12;
        break;
      default:
        numMes = 1;
        break;
    }

    let _dateFrom = dateFrom.split("-")
    let _dateTo = dateTo.split("-")
    let newDateFrom = new Date(_dateFrom[0], _dateFrom[1] - 1, _dateFrom[2], "0", "0", "0");
    let newDateTo = new Date(_dateTo[0], _dateTo[1] - 1, _dateTo[2], "23", "59", "59");
    let dashboardData = []
    let dateNow = new Date();
    let seletor = formSearch;

    if (seletor === "Data Fim") {
    } else if (seletor === "Mês") {
      newDateFrom = new Date(anoSelecionado, numMes - 1, 1, "0", "0", "0");
      dateNow.setMonth(numMes - 1);
      dateNow.setDate(1);
      let yesterdayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0);
      newDateTo = new Date(anoSelecionado, yesterdayMonth.getMonth(), yesterdayMonth.getDate(), "23", "59", "59");
    } else if (seletor === "Trimestre") {
      switch (trimestreSelecionado) {
        case ("1º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(0);
          let trimestre1 = new Date(anoSelecionado, dateNow.getMonth() + 4, 0);
          trimestre1.setDate(1);
          newDateTo = new Date(anoSelecionado, trimestre1.getMonth(), trimestre1.getDate() - 1, "23", "59", "59");
          break;
        case ("2º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 3, 1, "0", "0", "0");
          dateNow.setMonth(4);
          let trimestre2 = new Date(anoSelecionado, dateNow.getMonth() + 3, 0);
          trimestre2.setDate(1);
          newDateTo = new Date(anoSelecionado, trimestre2.getMonth(), trimestre2.getDate() - 1, "23", "59", "59");
          break;
        case ("3º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 6, 1, "0", "0", "0");
          dateNow.setMonth(7);
          let trimestre3 = new Date(anoSelecionado, dateNow.getMonth() + 3, 0);
          trimestre3.setDate(1);
          newDateTo = new Date(anoSelecionado, trimestre3.getMonth(), trimestre3.getDate() - 1, "23", "59", "59");
          break;
        case ("4º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 9, 1, "0", "0", "0");
          dateNow.setMonth(10);
          let trimestre4 = new Date(anoSelecionado, dateNow.getMonth() + 3, 0);
          trimestre4.setDate(1);
          newDateTo = new Date(String(parseInt(anoSelecionado) + 1), trimestre4.getMonth(), trimestre4.getDate() - 1, "23", "59", "59");
          break;
        default:
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(4);
          dateNow.setDate(dateNow.getDate() - 1);
          newDateTo = new Date(anoSelecionado, dateNow.getMonth() - 1, dateNow.getDate(), "23", "59", "59");
      }
    } else if (seletor === "Semestre") {
      switch (semestreSelecionado) {
        case ("1º Semestre"):
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(7);
          let semestre1 = new Date(anoSelecionado, dateNow.getMonth(), 0);
          dateNow.setDate(1);
          newDateTo = new Date(anoSelecionado, semestre1.getMonth() - 1, semestre1.getDate() - 1, "23", "59", "59");
          break;
        case ("2º Semestre"):
          newDateFrom = new Date(anoSelecionado, 6, 1, "0", "0", "0");
          dateNow.setMonth(13);
          let semestre2 = new Date(anoSelecionado, dateNow.getMonth(), 0);
          dateNow.setDate(1);
          newDateTo = new Date(String(parseInt(anoSelecionado) + 1), semestre2.getMonth() - 1, semestre2.getDate(), "23", "59", "59");
          break;
        default:
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(7);
          dateNow.setDate(dateNow.getDate() - 1);
          newDateTo = new Date(anoSelecionado, dateNow.getMonth() - 1, dateNow.getDate(), "23", "59", "59");
          break;
      }
    } else {
      newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
      newDateTo = new Date(anoSelecionado, 11, "31", "23", "59", "59");
    }

    await props.setDashboard(props.user, newDateFrom / 1000, newDateTo / 1000, originFetch, regexData.name).then((val) => {
      dashboardData = val
    });
    setDashboard(dashboardData);

    /* -------------------- Consultando todas as oportunidades válidas e fabricantes --------------------- */
    let oportunidadesValidas = []
    let allOpportunities = []
    let listaFabricante = []
    // console.log(opportunities)
    dashboardData?.opportunities?.map((opportunity) => {
      if (opportunity.matches.length > 0) {
        opportunity.matches.map(match => {
          if (match.items.length > 0) {
            oportunidadesValidas.push(opportunity)
          }

          if (!listaFabricante.includes(match.manufacturers)) {
            listaFabricante.push(match.manufacturers);
          }

          if (!allOpportunities.includes(opportunity)) {
            allOpportunities.push(opportunity)
          }
        })
      }
    })

    if (dashboardData !== 0) {
      const manufacturerNames = regexData.category.map(item => {
        return { "value": item.name, "label": item.name, "isDisabled": false }
      })
      manufacturerNames?.sort((a, b) => {
        if (a.value > b.value) {
          return true
        } else {
          return -1
        }
      })
      setStatusButtonManufacturers(true)
      setOptions(manufacturerNames)
    } else {
      setStatusButtonManufacturers(false)
    }
    setOportunidades(oportunidadesValidas)
    setAllOpportunities(allOpportunities)
    setLoading(false);

  }

  /* useEffect para controle do multi-select */
  useEffect(() => {
    let manufacturer = [];
    setTimeseriesManufacturer({});
    listManufacturer.map((currentManufacturer) => {
      manufacturer.push(currentManufacturer.value)
    })
    setOptionSelect(manufacturer)
    setStatusSelectCombined(false)
    setStatusSelectSpecific(false)
    setStatusSelectOpportunity(false)
    setStatusBarAndLineChart(false)
    setStatusChartMultiLine(false)
    setSpecificManufacturerSelected([])
    setOptionsSpecific([])
    setDataMultiLine([])
    setDataBar([])
    setDataLine([])
    setStatusSelectSpecificMultiManufacturer(false)
    setDataSelectSpecificComparison([])
    setLegend(false);
    setStatusDashboard(false);
    setItemSelecionado("")
    setOportunidadeSelecionada("")
    setDataPie(dataPieArray)
    setLegendManufacturer(legendManufacturerArray)
    setFirstLegendManufacturer(legendManufacturerArray)
    if (listManufacturer.length === 0) {
      setStatusFirstCharts(false)
      setStatusSelectSpecific(false)
      setStatusSelectOpportunity(false)
      setStatusBarAndLineChart(false)
      setDataBar([])
      setDataLine([])
      setDataMultiLine([])
      setStatusSelectSpecificMultiManufacturer(false)
      setSpecificManufacturerSelected([])
      setSpecificItemSelected("")
      setOptionsSpecific([])
      setStatusSelectCombined(false)
      setStatusPieChart(false)
    }
  }, [listManufacturer])

  // topManufacturers

  function topManufacturers() {
    let dictionary = {};
    let dictionary2 = {}
    let dictionary3 = {}

    // console.log(dashboard.opportunities)

    dashboard.opportunities.map(item => {
      let month = new Date(item.dateEnd * 1000);
      let monthList = month.toISOString().split('T')[0].split('-');
      let monthDate = monthList[0] + "-" + monthList[1];
      item.matches.map(match => {
        if (!dictionary[match.manufacturer]) {
          dictionary[match.manufacturer] = {}
        }

        if (!dictionary[match.manufacturer][monthDate]) {
          dictionary[match.manufacturer][monthDate] = 0
        }

        if (!dictionary2[match.manufacturer]) {
          dictionary2[match.manufacturer] = {}
        }

        if (!dictionary2[match.manufacturer][monthDate]) {
          dictionary2[match.manufacturer][monthDate] = 0
        }

        if (!dictionary3[match.manufacturer]) {
          dictionary3[match.manufacturer] = {}
        }

        if (!dictionary3[match.manufacturer][monthDate]) {
          dictionary3[match.manufacturer][monthDate] = 0
        }

        if (item.totalPrice > 0) {
          dictionary2[match.manufacturer][monthDate] = !!dictionary2[match.manufacturer][monthDate] ? dictionary2[match.manufacturer][monthDate] + 1 : 1;
        }

        if (item?.declined.declined === true || item?.declined?.expired === true) {
          dictionary3[match.manufacturer][monthDate] = !!dictionary3[match.manufacturer][monthDate] ? dictionary3[match.manufacturer][monthDate] + 1 : 1;
        }

        dictionary[match.manufacturer][monthDate] = !!dictionary[match.manufacturer][monthDate] ? dictionary[match.manufacturer][monthDate] + 1 : 1;
      })
    })

    let formData1 = Object.entries(dictionary).map(obj => {
      return {
        "Fabricante": obj[0],
        ...obj[1]
      }
    });

    let formData2 = Object.entries(dictionary2).map(obj => {
      return {
        "Fabricante": obj[0],
        ...obj[1]
      }
    });

    let formData3 = Object.entries(dictionary3).map(obj => {
      return {
        "Fabricante": obj[0],
        ...obj[1]
      }
    });

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet((formData1));
    const ws2 = XLSX.utils.json_to_sheet((formData2));
    const ws3 = XLSX.utils.json_to_sheet((formData3));
    const workSheets = {
      "TOP Incidências": ws,
      "TOP Participações": ws2,
      "TOP Declínios": ws3,
    };
    // console.log(timeserieDecline());
    const wb = { Sheets: workSheets, SheetNames: ['TOP Incidências', 'TOP Participações', 'TOP Declínios'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataToDownload = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataToDownload, "TOP " + regexData.name + " - " + originFetch + fileExtension);

  }

  /* ================ Função para iniciar a exibição da interface =================== */
  function startInterface() {
    if (listManufacturer.length > 1) {
      setStatusSelectCombined(true)
    }
    setStatusSelectSpecific(false)
    setStatusSelectOpportunity(false)
    setStatusBarAndLineChart(false)
    setStatusChartMultiLine(false)
    setSpecificManufacturerSelected([])
    setOptionsSpecific([])
    setStatusFirstCharts(true)
    setDataMultiLine([])
    setDataBar([])
    setDataLine([])
    setStatusSelectSpecificMultiManufacturer(false)
    setDataSelectSpecificComparison([])
    setLegend(false);
    setStatusDashboard(false);
    setItemSelecionado("")
    setOportunidadeSelecionada("")

    let manufacturer = []

    listManufacturer.map(manufacturerData => {
      manufacturer.push(manufacturerData.value)
      dataFilterManufacturer(manufacturerData.value)
    })
    let oportunidadesFabricante = [];
    let dataOpportunitiesManufacturers = []
    allOpportunities.map(opportunity => {
      opportunity.matches.map(match => {
        manufacturer.map(name => {
          if (match.manufacturer === name && match.items.length > 0) {
            if (!oportunidadesFabricante.includes(opportunity)) {
              oportunidadesFabricante.push(opportunity)
            }
            dataOpportunitiesManufacturers.push({
              "manufacturer": name,
              "opportunity": opportunity
            })
          }
        })
      })
    })
    setOpportunitiesManufacturer(oportunidadesFabricante)

    let dataManufacturers = []
    manufacturer.map(option => {
      dataManufacturers.push({
        "manufacturer": option,
        "opportunity": dataOpportunitiesManufacturers.filter(data => data.manufacturer === option).map(o => { return o.opportunity })
      })
    })
    setProposalsManufacturers(dataManufacturers)

    let itensFiltradosFabricante = [];
    let itemsManufacturer = []
    manufacturer.map(name => {
      oportunidadesFabricante.map(opportunity => {
        opportunity.matches.map(match => {
          if (match.manufacturer === name) {
            match.items.map((item) => {
              if (!itensFiltradosFabricante.includes(item)) {
                itensFiltradosFabricante.push(item);
              }
            })
          }
        })
      })
      itemsManufacturer.push({
        "manufacturer": name,
        "items": itensFiltradosFabricante
      })
      itensFiltradosFabricante = []
    })
    let allItems = []
    itemsManufacturer.map(data => {
      data.items.map(item => {
        allItems.push(item)
      })
    })

    //Metodo para salvar os itens em comuns entre os fabricantes
    allItems.map(item => {
      if (itemsManufacturerSelected.includes(item)) {
        if (!itemsEqualsManufacturerSelected.includes(item)) {
          itemsEqualsManufacturerSelected.push(item)
        }
      } else {
        itemsManufacturerSelected.push(item)
      }
    })
    setEqualsItems(itemsEqualsManufacturerSelected)
    setStatusPieChart(true)
  }

  /* ==================== Função chamada após o select dos fabricantes ================= */
  function dataFilterManufacturer(manufacturer) {
    setOportunidadeSelecionada("");
    setItemSelecionado("");
    setClickPie(false);
    setStatusDashboard(true); //Atualizando o status do dashboard para apresentar em tela

    /* --------------- buscando as oportunidades do fabricante selecionado --------------- */
    //AQUI ESTÁ UM DOS PROBLEMAS DE FILTRO (match.items)
    let oportunidadesFabricante = [];
    allOpportunities.map((opportunity) => {
      opportunity.matches.map((match) => {
        //&& match.items.length > 0
        if (match.manufacturer === manufacturer) {
          if (!oportunidadesFabricante.includes(opportunity)) {
            oportunidadesFabricante.push(opportunity);
          }
        }
      });
    });

    dataManufacturer.push({
      manufacturer: manufacturer,
      opportunities: oportunidadesFabricante,
    });
    setOpportunityManufacturer(dataManufacturer);

    /* ------- Consultado as oportunidades cotadas pela Empresa -----------*/
    let tmpObj = timeseriesManufacturer;
    tmpObj[manufacturer] = oportunidadesFabricante;
    setTimeseriesManufacturer(tmpObj);

    let presented = 0;
    let declined = 0;
    let noReply = 0;
    let totalVolumeInReal = 0;
    oportunidadesFabricante.map((opportunity) => {
      if (
        opportunity.status === "Cotação apresentada" &&
        opportunity.totalPrice === 0
      ) {
        //declinada
        declined++;
      } else if (
        opportunity.status === "Cotação apresentada" &&
        opportunity.totalPrice > 0
      ) {
        //cotação apresentada
        presented++;
        totalVolumeInReal += opportunity.totalPrice;
      } else if (
        opportunity.status !== "Cotação apresentada" ||
        opportunity.totalPrice === null
      ) {
        //sem resposta
        noReply++;
      }
    });

    legendManufacturerArray.push({
      presented: presented,
      declined: declined,
      noReply: noReply,
      totalVolumeInReal: totalVolumeInReal,
      name: manufacturer,
    });

    setLegendManufacturer(legendManufacturerArray);
    setFirstLegendManufacturer(legendManufacturerArray);

    /* ------------------- buscando os itens do fabricante ------------------- */
    let itensFiltradosFabricante = [];
    let itensRepeticao = [];
    oportunidadesFabricante.map((opportunity) => {
      opportunity.matches.map((match) => {
        if (match.manufacturer === manufacturer) {
          match.items.map((item) => {
            itensRepeticao.push(item);
            if (!itensFiltradosFabricante.includes(item)) {
              itensFiltradosFabricante.push(item);
            }
          });
        }
      });
    });
    if (itensFiltradosFabricante.length === 0) {
      setLegend(true);
      setStatusDashboard(false);
    } else {
      setLegend(false);
    }

    /* ------ Registrando quantidade de aparecimento de item em oportunidades diferentes ----- */
    let quantidadePorItem = {};
    for (let i = 0; i < itensRepeticao.length; i++) {
      if (!quantidadePorItem[itensRepeticao[i]]) {
        quantidadePorItem[itensRepeticao[i]] = 0;
      }
      quantidadePorItem[itensRepeticao[i]]++;
    }
    let qtdItem = [];
    for (let prop in quantidadePorItem) {
      qtdItem.push(quantidadePorItem[prop]);
    }

    /* --- Buscando nas oportunidades do fabricantes quais tiveram proposta --- */
    let itemProposta = [];
    let quantidade = 0;
    oportunidadesFabricante.map((oportunidade) => {
      quantidade = 0;
      if (oportunidade.totalPrice !== null) {
        quantidade++;
      }
      itemProposta.push({
        oportunidade: oportunidade,
        descrição: oportunidade.description,
        quantidade: quantidade,
      });
    });
    let auxProposal = Object.assign(oportunidadeComProposta, itemProposta);
    setOportunidadeComProposta(auxProposal);

    if (oportunidadesFabricante.length === 0) {
      dataPieArray.push({
        message:
          "Ainda não existe match de itens para o Fabricante selecionado - ",
        title: manufacturer,
      });
    } else {
      dataPieArray.push({
        labels: itensFiltradosFabricante,
        series: qtdItem,
        title: manufacturer,
      });
    }
    setDataPie(dataPieArray);
  }

  /* ------------------------ Baixar Indicadores da Fabricante --------------------------*/

  function timestampToDate(dataIn) {
    try {
      let data = new Date(dataIn * 1000)
      data.setHours(data.getHours() - 3)
      data = data.toISOString().replace(/T/, ' ').replace(/\..+/, '').replace("Z", '');
      let newData = data.split(" ");
      let newFormData = newData[0].split("-");
      let outPutData = newFormData[2] + "/" + newFormData[1] + "/" + newFormData[0] + " " + newData[1]
      return outPutData;
    }
    catch (e) {
      return "31/12/2099 23:59:59"
    }
  }

  function downloadTimeseriesManufacturer(manufacturerSelected) {
    // console.log(timeseriesManufacturer[manufacturerSelected]);
    // console.log(dashboard.opportunities)
    let formData = dashboard.opportunities.filter(probe => !!probe.matches.some(o => o.manufacturer === manufacturerSelected)).map(item => {
      let manufacturers = item.matches?.map(o => o.manufacturer)?.join(",")
      let declineStatus = "";

      if (item.declined?.declined) {
        if (item.declined.declined) {
          declineStatus = "Declinado";
        } else if (item.declined?.expired) {
          declineStatus = "Expirado"
        } else {
          declineStatus = "Não Computado"
        }
      } else {
        declineStatus = "Não Computado"
      }

      let localStatus = item.localStatus.length > 0 ? item.localStatus[0].statusName.name : "Sem Status";
      let note = item.localStatus.length > 0 ? item.localStatus[0].note : "Sem comentários";

      return {
        "Número": item.index,
        "Descrição Obj. Contratação": item.description,
        "Data Início": timestampToDate(item.dateStart),
        "Data Fim": timestampToDate(item.dateEnd),
        "Origem": item.origin,
        "Status": item.status,
        "Declínio supervisionado": declineStatus,
        "Fabricantes": manufacturers,
        "Participação": item.totalPrice > 0 ? "Ofertado" : "Não ofertado",
        "Status Interno": localStatus,
        "Comentário": note,
        "Tem Propostas": item.hasProposal === true ? "Sim" : "Não",
      }
    })

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(formData);
    const workSheets = {
      "Oportunidades": ws,
      "Total": XLSX.utils.json_to_sheet(Object.entries(timeserieTotal(manufacturerSelected))),
      "Participados": XLSX.utils.json_to_sheet(Object.entries(timeserieQuoted(manufacturerSelected))),
      "Sem Participação": XLSX.utils.json_to_sheet(Object.entries(timeserieNotQuoted(manufacturerSelected))),
      "Declinados": XLSX.utils.json_to_sheet(Object.entries(timeserieDeclined(manufacturerSelected))),
    };
    // console.log(timeserieDecline());
    const wb = { Sheets: workSheets, SheetNames: ['Oportunidades', "Total", "Participados", "Sem Participação", "Declinados"] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataToDownload = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataToDownload, manufacturerSelected + fileExtension);
  }

  function timeserieQuoted(manufacturerSelected) {
    let dictionary = {};

    dashboard.opportunities.filter(probe => !!probe.matches.some(o => o.manufacturer === manufacturerSelected)).filter(o => o.status === "Cotação apresentada").map(item => {
      let month = new Date(item.dateEnd * 1000);
      dictionary[month.toLocaleString('default', { month: 'long' })] = (!!dictionary[month.toLocaleString('default', { month: 'long' })]) ? dictionary[month.toLocaleString('default', { month: 'long' })] + 1 : 1;
    })

    return (dictionary);
  }

  function timeserieNotQuoted(manufacturerSelected) {
    let dictionary = {};

    dashboard.opportunities.filter(probe => probe.status !== "Cotação apresentada").filter(probe => probe.matches.some(o => o.manufacturer === manufacturerSelected)).map(item => {
      let month = new Date(item.dateEnd * 1000);
      dictionary[month.toLocaleString('default', { month: 'long' })] = (!!dictionary[month.toLocaleString('default', { month: 'long' })]) ? dictionary[month.toLocaleString('default', { month: 'long' })] + 1 : 1;
    })

    return (dictionary);
  }

  function timeserieDeclined(manufacturerSelected) {
    let dictionary = {};

    dashboard.opportunities.filter(probe => probe.declined?.declined === true || probe.declined?.expired === true).filter(probe => probe.matches.some(o => o.manufacturer === manufacturerSelected)).map(item => {
      let month = new Date(item.dateEnd * 1000);
      dictionary[month.toLocaleString('default', { month: 'long' })] = (!!dictionary[month.toLocaleString('default', { month: 'long' })]) ? dictionary[month.toLocaleString('default', { month: 'long' })] + 1 : 1;
    })

    return (dictionary);
  }

  function timeserieTotal(manufacturerSelected) {
    let dictionary = {};

    dashboard.opportunities.filter(probe => probe.matches.some(o => o.manufacturer === manufacturerSelected)).map(item => {
      let month = new Date(item.dateEnd * 1000);
      dictionary[month.toLocaleString('default', { month: 'long' })] = (!!dictionary[month.toLocaleString('default', { month: 'long' })]) ? dictionary[month.toLocaleString('default', { month: 'long' })] + 1 : 1;
    })

    return (dictionary);
  }

  /*---------------- função chamada após o click no gráfico de pizza ----------------*/
  async function filterItem(item) {
    setLoadingClickPie(true)
    setStatusSelectSpecificMultiManufacturer(false)
    setStatusChartMultiLine(false)
    setStatusSelectCombined(false)
    setStatusSelectOpportunity(false)
    setStatusBarAndLineChart(false)
    setDataBar([])
    setDataLine([])
    setOportunidadeSelecionada("")
    setStatusSelectSpecific(false)

    let legendItemArray = [];

    //filtrando oportunidades que possuem o item selecionado
    let oportunidadeParaOItemSelecionado = filterOpportunityItem(item)
    setPropostaFabricanteItem(oportunidadeParaOItemSelecionado)

    let index = []
    oportunidadeParaOItemSelecionado.map(op => {
      index.push(op.index)
    })

    await props.initProposalData(props.user, index).then((val) => {
      proposalSelectedManufacturer = val
    });
    setCotacoesApresentadaItemPieClick(proposalSelectedManufacturer)

    //Consultado as oportunidades cotadas pela Empresa
    let presented = 0
    let declined = 0
    let noReply = 0
    let totalVolumeInReal = 0;
    oportunidadeParaOItemSelecionado.map(opportunity => {
      if (opportunity.status === "Cotação apresentada" && opportunity.totalPrice === 0) {
        //declinada
        declined++
      } else if (opportunity.status === "Cotação apresentada" && opportunity.totalPrice > 0) {
        //cotação apresentada
        presented++
        totalVolumeInReal += opportunity.totalPrice
      } else if (opportunity.status !== "Cotação apresentada" || opportunity.totalPrice === null) {
        //sem resposta
        noReply++
      }
    })

    legendItemArray.push({
      "presented": presented,
      "declined": declined,
      "noReply": noReply,
      "totalVolumeInReal": totalVolumeInReal
    })
    setLegendManufacturer(legendItemArray)

    //conferindo se o item selecionado possui espaço em branco
    const text = item;
    const temEspaco = (text?.indexOf(' ') > 0);

    /* Formatando item com espaço para pegar apenas primeira letra apos o espaço, remover os espaços e colocar 
    minusculo */
    let currentItem;
    if (temEspaco) { //caso a descrição do item seja dividida por espaço
      const textV1 = text.toLowerCase();  //colocando tudo em minusculo 
      const textV2 = textV1.split(" ");    //dividindo item que tem mais de uma palavra
      currentItem = textV2[0] + " " + textV2[1].substr(0, 1); // adicionando apenas a primeira letra que aparece dps do espaço
      currentItem = currentItem.replace(/\s/g, '');
    } else {
      currentItem = item.replace(/\s/g, '');
      currentItem = currentItem.toLowerCase();
    }

    /* Capturando quantidade de vezes que o item aparece em propostas 
     e capturando a quantidade de vezes que este item foi cotado, e por quem foi cotado */
    let quantidade = 0;
    let qtdCotacoes = 0;
    let cotacoes = [];
    let cotacoesApresentadaItem = [];
    proposalSelectedManufacturer.map(proposal => {
      let itemProposta = proposal.itemDescription.replace(/\s/g, '');
      itemProposta = itemProposta.substr(0, (currentItem.length));
      itemProposta = itemProposta.toLowerCase();
      if (currentItem === itemProposta) {
        if (proposal.totalPrice !== 0) { //pegando as cotações que não declinaram 
          if (!cotacoesApresentadaItem.includes(proposal)) {
            cotacoesApresentadaItem.push(proposal)
          }
          cotacoes.push(
            {
              "corporateName": proposal.corporateName,
              "totalPrice": proposal.totalPrice,
              "itemDescription": proposal.itemDescription,
            })
          qtdCotacoes++;
        }
      }
    })

    if (quantidade === 0) {
      setClickPie(false)
      setLegend(true)
      setItemSelecionado("")
    } else {
      setLegend(false)
    }

    //salvando dados para o gráfico de barras
    let dataBarArray = []
    dataBarArray.push({
      "data": oportunidadeParaOItemSelecionado.length,
      "categories": "Oportunidades",
      "title": "Oportunidades Vs Cotações apresentadas"
    })
    dataBarArray.push({
      "data": qtdCotacoes,
      "categories": "Cotações",
      "title": "Oportunidades Vs Cotações apresentadas"
    })
    setDataBar(dataBarArray)

    //buscando a quantidade de aparições do item de acordo com os meses
    let mesReferencia = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let mouth = []
    oportunidadeParaOItemSelecionado.map(opportunity => {
      let date = new Date(parseInt(opportunity.dateEnd + "000"))
      mouth.push(date.getMonth())
    })
    mouth.map(o => {
      mesReferencia[o - 1]++
    })

    //Referenciando cada posição do array de quantidades de aparições com o nome do mes correto
    let mesDescricao = [];
    for (let i = 0; i < mesReferencia.length; i++) {
      switch (i) {
        case 0:
          mesDescricao.push({
            "Mes": "Jan",
            "Quantidade": mesReferencia[0],
          })
          break;
        case 1:
          mesDescricao.push({
            "Mes": "Fev",
            "Quantidade": mesReferencia[1],
          })
          break;
        case 2:
          mesDescricao.push({
            "Mes": "Mar",
            "Quantidade": mesReferencia[2],
          })
          break;
        case 3:
          mesDescricao.push({
            "Mes": "Abr",
            "Quantidade": mesReferencia[3],
          })
          break;
        case 4:
          mesDescricao.push({
            "Mes": "Mai",
            "Quantidade": mesReferencia[4],
          })
          break;
        case 5:
          mesDescricao.push({
            "Mes": "Jun",
            "Quantidade": mesReferencia[5],
          })
          break;
        case 6:
          mesDescricao.push({
            "Mes": "Jul",
            "Quantidade": mesReferencia[6],
          })
          break;
        case 7:
          mesDescricao.push({
            "Mes": "Ago",
            "Quantidade": mesReferencia[7],
          })
          break;
        case 8:
          mesDescricao.push({
            "Mes": "Set",
            "Quantidade": mesReferencia[8],
          })
          break;
        case 9:
          mesDescricao.push({
            "Mes": "Out",
            "Quantidade": mesReferencia[9],
          })
          break;
        case 10:
          mesDescricao.push({
            "Mes": "Nov",
            "Quantidade": mesReferencia[10],
          })
          break;
        case 11:
          mesDescricao.push({
            "Mes": "Dez",
            "Quantidade": mesReferencia[11],
          })
          break;
      }
    }

    //separando apenas as quantidades de ocorrencias para o gráfico
    let categoriesMes = [];
    mesDescricao.map(mes => {
      categoriesMes.push(mes.Mes)
    })

    let dataLineArray = []
    dataLineArray.push({
      "quantity": mesReferencia,
      "description": categoriesMes
    })
    setDataLine(dataLineArray)

    //gerando dados para preencher o segundo select
    let itensEspecificos = [];
    cotacoesApresentadaItem.map(proposta => {
      if (!itensEspecificos.includes(proposta.itemDescription)) {
        itensEspecificos.push(proposta.itemDescription)
      }
    })
    setDataSelectSpecific(itensEspecificos)

    setStatusSelectSpecific(true)
    setStatusFirstCharts(true)
    setItemSelecionado(item)
    setLoadingClickPie(false)
  }

  /* ======= Função para buscar as oportunidades do item selecionado ======== */
  function filterOpportunityItem(item) {
    let oportunidadeParaOItemSelecionado = [];
    oportunidadeComProposta.map((opportunity) => {
      Object.keys(opportunity.oportunidade.matches).forEach(function (match) {
        opportunity.oportunidade.matches[match].items.map((itemName) => {
          if (itemName === item) {
            if (!oportunidadeParaOItemSelecionado.includes(opportunity.oportunidade)) {
              oportunidadeParaOItemSelecionado.push(opportunity.oportunidade)
            }
          }
        })
      })
    })
    return oportunidadeParaOItemSelecionado
  }

  /* ================== Função chamada após select de item comum entre os fabricantes ===================== */
  async function selectEqualsItem(e) {

    setLoadingSelectMultiManufacturer(true)
    setStatusSelectSpecificMultiManufacturer(false)
    setItemSelecionado(e.target.value)
    setStatusSelectOpportunity(false)
    setStatusBarAndLineChart(false)
    setDataLine([])
    setDataBar([])
    setStatusSelectSpecific(false)
    setSpecificItemSelected("")
    setOptionsSpecific([])
    setSpecificManufacturerSelected([])
    setStatusChartMultiLine(false)

    let opportunitiesItem = []
    opportunitiesManufacturer.map(opportunity => {
      opportunity.matches.map(match => {
        match.items.map((itemName) => {
          if (itemName === e.target.value) {
            if (!opportunitiesItem.includes(opportunity)) {
              opportunitiesItem.push(opportunity)
            }
          }
        })
      })
    })
    setOpportunitiesItemGeneric(opportunitiesItem)

    // filtrando oportunidades que possui o item e os fabricantes selecionados
    let opportunitiesItemAndManufacturer = []
    optionSelect.map((manufacturer) => {
      opportunitiesItem.map((opportunity) => {
        opportunity.matches.map(match => {
          if (match.manufacturer === manufacturer) {
            if (!opportunitiesItemAndManufacturer.includes(opportunity)) {
              opportunitiesItemAndManufacturer.push(opportunity)
            }
          }
        })
      })
    })

    let index = []
    opportunitiesItemAndManufacturer.map(opportunity => {
      index.push(opportunity.index)
    })

    // salvando cotações apresentadas para o item
    let cotacoesApresentadaItem = []
    await props.initProposalData(props.user, index).then((val) => {
      cotacoesApresentadaItem = val
    });

    //salvando descrições especificas para o item
    let itensEspecificos = [];
    cotacoesApresentadaItem.map(proposta => {
      if (!itensEspecificos.includes(proposta.itemDescription)) {
        itensEspecificos.push(proposta.itemDescription)
      }
    })
    setDataSelectSpecificComparison(itensEspecificos)

    // salvando quantidade de ocorrencias de cada fabricante
    let manufacturerOptions = []
    opportunitiesItemAndManufacturer.map(opportunity => {
      opportunity.matches.map(match => {
        optionSelect.map(option => {
          if (option === match.manufacturer) {
            manufacturerOptions.push({
              "opportunity": opportunity,
              "match": match.manufacturer,
            })
          }
        })
      })
    })

    let manufacturerData = []
    manufacturerOptions.map(option => {
      manufacturerData.push(option.match)
    })
    manufacturerData.sort()

    let manufacturerFormatted = []
    optionSelect.map(option => {
      manufacturerFormatted.push({
        "manufacturer": option,
        "data": manufacturerData.filter(data => data === option).length
      })
    })

    //salvando dados para o multi-select de itens especificos
    let options = []
    manufacturerFormatted.map(manufacturer => {
      if (manufacturer.data > 0) {
        options.push({
          "value": manufacturer.manufacturer,
          "label": manufacturer.manufacturer,
        })
      }
    })
    setOptionsSpecific(options)

    //separando oportunidades para cada fabricante
    let proposalManufacturerRepeated = [];
    optionSelect.map(option => {
      proposalManufacturerRepeated.push({
        "manufacturer": option,
        "data": manufacturerOptions.filter(data => data.match === option).map(o => { return o.opportunity })
      })
    })
    let proposalManufacturerFormatted = []
    let aux = []

    proposalManufacturerRepeated.map(dataManufacturer => {
      let proposal = []
      dataManufacturer.data.map(o => {
        if (!aux.includes(o.index)) {
          aux.push(o.index)
          proposal.push(o)
        }
      })

      proposalManufacturerFormatted.push({
        "manufacturer": dataManufacturer.manufacturer,
        "data": proposal
      })
      aux.slice(0, aux.length)
      proposal.slice(0, proposal.length)
    })

    //salvando dados para o gráfico de barras
    let dataBarArray = []
    proposalManufacturerFormatted.map((manufacturer) => {
      dataBarArray.push({
        "data": manufacturer.data.length,
        "categories": manufacturer.manufacturer,
        "title": "Oportunidades do item por fabricante"
      })
    })

    //buscando a quantidade de aparições do item de acordo com os meses
    let mesReferencia = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    let dataMultiLineLocal = []
    let mouth = []

    proposalManufacturerFormatted.map((option) => {
      option.data.map(opportunity => {
        let date = new Date(parseInt(opportunity.dateEnd + "000"))
        mouth.push(date.getMonth())
      })
      mouth.map(o => {
        mesReferencia[o - 1]++
      })
      dataMultiLineLocal.push({
        'manufacturer': option.manufacturer,
        "mes": mesReferencia
      })
      mesReferencia = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      mouth = []
    })
    setDataMultiLine(dataMultiLineLocal)

    setDataBar(dataBarArray)
    setStatusChartMultiLine(true)
    setStatusSelectSpecificMultiManufacturer(true)

    setLoadingSelectMultiManufacturer(false)
  }

  /* ================== Função chamada após select de item e fabricantes especificos ===================== */
  async function specificSelect() {

    setStatusChartMultiLine(false)
    setStatusSelectSpecificMultiManufacturer(false)
    setLoadingSelectSpecificMultiManufacturer(true)

    let manufacturer = []
    specificManufacturerSelected.map(manufacturerData => {
      manufacturer.push(manufacturerData.value)
    })

    // filtrando as oportunidades que possuem os fabricantes selecionados
    let oportunidadesFabricante = [];
    opportunitiesItemGeneric.map(opportunity => {
      opportunity.matches.map(match => {
        manufacturer.map(name => {
          if (match.manufacturer === name && match.items.includes(itemSelecionado)) {
            if (!oportunidadesFabricante.includes(opportunity)) {
              oportunidadesFabricante.push(opportunity)
            }
          }
        })
      })
    })
    let index = []
    oportunidadesFabricante.map(opportunity => {
      index.push(opportunity.index)
    })

    // salvando cotações apresentadas para o item
    let cotacoesApresentadaItem = []
    await props.initProposalData(props.user, index).then((val) => {
      cotacoesApresentadaItem = val
    });

    //separando as oportunidades relacionadas ao item selecionado
    let controlOpportunities = []
    cotacoesApresentadaItem.map(prop => {
      if (!controlOpportunities.includes(prop.index) && prop.itemDescription === specificItemSelected) {
        controlOpportunities.push(prop.index)
      }
    })

    oportunidadesFabricante = oportunidadesFabricante.filter(op => (controlOpportunities.includes(op.index)))

    let manufacturerOptions = []
    oportunidadesFabricante.map(opportunity => {
      opportunity.matches.map(match => {
        manufacturer.map(option => {
          if (option === match.manufacturer) {
            manufacturerOptions.push({
              "opportunity": opportunity,
              "match": match.manufacturer,
            })
          }
        })
      })
    })
    let manufacturerData = []
    manufacturerOptions.map(option => {
      manufacturerData.push(option.match)
    })
    manufacturerData.sort()
    let manufacturerFormatted = []
    optionSelect.map(option => {
      manufacturerFormatted.push({
        "manufacturer": option,
        "data": manufacturerData.filter(data => data === option).length

      })
    })

    //separando oportunidades para cada fabricante
    let proposalManufacturerFormatted = [];
    manufacturer.map(option => {
      proposalManufacturerFormatted.push({
        "manufacturer": option,
        "data": manufacturerOptions.filter(data => data.match === option).map(o => { return o.opportunity })
      })
    })

    //salvando dados para o gráfico de barras
    let dataBarArray = []
    proposalManufacturerFormatted.map((manufacturer) => {
      dataBarArray.push({
        "data": manufacturer.data.length,
        "categories": manufacturer.manufacturer,
        "title": "Oportunidades do item por fabricante"
      })
    })
    setDataBar(dataBarArray)

    //buscando a quantidade de aparições do item de acordo com os meses
    let mesReferencia = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    let dataMultiLineLocal = []
    let mouth = []

    proposalManufacturerFormatted.map((option) => {
      option.data.map(opportunity => {
        let date = new Date(parseInt(opportunity.dateEnd + "000"))
        mouth.push(date.getMonth())
      })
      mouth.map(o => {
        mesReferencia[o - 1]++
      })
      dataMultiLineLocal.push({
        'manufacturer': option.manufacturer,
        "mes": mesReferencia
      })
      mesReferencia = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    })
    setDataMultiLine(dataMultiLineLocal)

    setStatusSelectSpecificMultiManufacturer(true)
    setStatusChartMultiLine(true)
    setLoadingSelectSpecificMultiManufacturer(false)
  }

  /* ============================ Função chamada após select de item especifico - PIE ============================= */
  function selectItem(e) {
    setStatusChartMultiLine(false)
    setStatusBarAndLineChart(false)
    setStatusFirstCharts(true)
    setStatusSelectOpportunity(true)
    setOportunidadeSelecionada("")
    setItemSelecionado(e.target.value)

    //filtrando oportunidades que possuem o item selecionados, e separando seus meses
    let mouth = []
    let controlIndex = []
    let proposals = []
    cotacoesApresentadaItemPieClick.map(proposal => {
      if (proposal.itemDescription === e.target.value) {
        proposals.push(proposal)
        let date = new Date(parseInt(proposal.dateEnd + "000"))
        if (!controlIndex.includes(proposal.index)) {
          controlIndex.push(proposal.index)
          mouth.push(date.getMonth())
        }
      }
    })
    setCotacoesApresentadasItemEspecifico(proposals)

    //salvando oportunidades para o select
    setOpportunities(controlIndex)
    //Consultado as oportunidades cotadas pela Empresa
    let presented = 0
    let declined = 0
    let noReply = 0
    let totalVolumeInReal = 0;
    let legendItemSpecificArray = []

    //contabilizando as oportunidades existentes para o item
    propostaFabricanteItem.filter(o => controlIndex.includes(o.index)).map(opportunity => {
      if (opportunity.status !== "Cotação apresentada" || opportunity.totalPrice === null) {
        //sem resposta
        noReply++
      }
    })

    //filtrando dados da empresa correste através das propostas para o item
    proposals.map(prop => {
      if (prop.corporateName === currentCompany && prop.totalPrice > 0) {
        //cotação apresentada empresa
        presented++
        totalVolumeInReal += prop.totalPrice
      } else if (prop.corporateName === currentCompany && prop.totalPrice === 0) {
        //declinada
        declined++
      }
    })

    legendItemSpecificArray.push({
      "name": e.target.value,
      "presented": presented,
      "declined": declined,
      "noReply": noReply,
      "totalVolumeInReal": totalVolumeInReal
    })
    
    setLegendManufacturer(legendItemSpecificArray)

    //salvando dados para o gráfico de barras
    let dataBarArray = []
    dataBarArray.push({
      "data": parseInt(controlIndex.length),
      "categories": "Oportunidades",
      "title": "Oportunidades Vs Cotações apresentadas"
    })
    dataBarArray.push({
      "data": presented,
      "categories": "Cotações",
      "title": "Oportunidades Vs Cotações apresentadas"
    })
    setDataBar(dataBarArray)

    //buscando a quantidade de aparições do item de acordo com os meses
    let mesReferencia = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    mouth.map(o => {
      mesReferencia[o - 1]++
    })

    //Referenciando cada posição do array de quantidades de aparições com o nome do mes correto
    let mesDescricao = [];
    for (let i = 0; i < mesReferencia.length; i++) {
      switch (i) {
        case 0:
          mesDescricao.push({
            "Mes": "Jan",
            "Quantidade": mesReferencia[0],
          })
          break;
        case 1:
          mesDescricao.push({
            "Mes": "Fev",
            "Quantidade": mesReferencia[1],
          })
          break;
        case 2:
          mesDescricao.push({
            "Mes": "Mar",
            "Quantidade": mesReferencia[2],
          })
          break;
        case 3:
          mesDescricao.push({
            "Mes": "Abr",
            "Quantidade": mesReferencia[3],
          })
          break;
        case 4:
          mesDescricao.push({
            "Mes": "Mai",
            "Quantidade": mesReferencia[4],
          })
          break;
        case 5:
          mesDescricao.push({
            "Mes": "Jun",
            "Quantidade": mesReferencia[5],
          })
          break;
        case 6:
          mesDescricao.push({
            "Mes": "Jul",
            "Quantidade": mesReferencia[6],
          })
          break;
        case 7:
          mesDescricao.push({
            "Mes": "Ago",
            "Quantidade": mesReferencia[7],
          })
          break;
        case 8:
          mesDescricao.push({
            "Mes": "Set",
            "Quantidade": mesReferencia[8],
          })
          break;
        case 9:
          mesDescricao.push({
            "Mes": "Out",
            "Quantidade": mesReferencia[9],
          })
          break;
        case 10:
          mesDescricao.push({
            "Mes": "Nov",
            "Quantidade": mesReferencia[10],
          })
          break;
        case 11:
          mesDescricao.push({
            "Mes": "Dez",
            "Quantidade": mesReferencia[11],
          })
          break;
      }
    }

    //separando apenas as quantidades de ocorrencias para o gráfico
    let categoriesMes = [];
    mesDescricao.map(mes => {
      categoriesMes.push(mes.Mes)
    })
    let dataLineArray = []
    dataLineArray.push({
      "quantity": mesReferencia,
      "description": categoriesMes
    })
    setDataLine(dataLineArray)
  }

  function selectOpportunity(e) {
    setStatusBarAndLineChart(true)
    setOportunidadeSelecionada(e.target.value)

    /* ---------------------- Preenchendo gráfico de linha & coluna ------------------ */
    let propostasItemEspecificoNaoDeclinada = [];
    cotacoesApresentadasItemEspecifico.map(proposta => {
      if (proposta.totalPrice > 0) {
        propostasItemEspecificoNaoDeclinada.push(proposta)
      }
    })

    //separando o nome dos concorrentes
    let concorrentesNome = []
    propostasItemEspecificoNaoDeclinada.map(cot => {
      concorrentesNome.push(cot.corporateName)
    })

    //separando o totalPrice dos concorrentes
    let concorrentesTotalPrice = []
    propostasItemEspecificoNaoDeclinada.map(cot => {
      concorrentesTotalPrice.push(cot.totalPrice)
    })

    //separando o valor cotado pela empresa do item selecionado
    let empresaOneTotalPrice = 0;
    propostasItemEspecificoNaoDeclinada.map(cot => {
      if (cot.corporateName === currentCompany) {
        empresaOneTotalPrice = cot.totalPrice;
      }
    })
    setQuotationValueEmpresa(empresaOneTotalPrice)

    let empresaTotalPrice = [];
    for (let i = 0; i < concorrentesTotalPrice.length; i++) {
      empresaTotalPrice.push(empresaOneTotalPrice)
    }

    let dataBarAndLineArray = []
    dataBarAndLineArray.push({
      "dataCompany": empresaTotalPrice,
      "dataConcurrent": concorrentesTotalPrice,
      "labels": concorrentesNome
    })
    setDataBarAndLine(dataBarAndLineArray)
  }

  /* ======================== Função de Download =================== */
  /*  function downloadTable(e) {
     e.preventDefault();
 
     const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
     let dataTable = []
 
     firstLegendManufacturer.map(manufacturerData => {
       let formDataPie = []
       let formDataLegend = []
       let formDataOpportunities = []
 
       dataPie.map(pie => {
         if (pie.title === manufacturerData.name) {
           pie.labels.map((label, index) => {
             formDataPie.push({
               "Item": pie.labels[index],
               "Quantidade": pie.series[index]
             })
           })
         }
       })
 
       formDataLegend.push({
         "Fabricante": manufacturerData.name,
         "Cotação apresentada": manufacturerData.presented,
         "Cotação sem resposta": manufacturerData.noReply,
         "Volume total em real": manufacturerData.totalVolumeInReal
       })
 
       proposalsManufacturers.map(data => {
         if (data.manufacturer === manufacturerData.name) {
           data.opportunity.map(opportunity => {
             formDataOpportunities.push({
               "Número da opportunidade": opportunity.index,
               "Descrição": opportunity.description,
               "Origem da oportunidade": opportunity.origin,
               "Status": opportunity.status,
               "Quantidade de propostas": opportunity.proposal.length
             })
           })
         }
       })
 
       dataTable.push({
         "Manufacturer": manufacturerData.name,
         "formDataPie": formDataPie,
         "formDataLegend": formDataLegend,
         "formDataOpportunities": formDataOpportunities,
       })
     })
 
     if (dataTable.length === 3) {
       const ws1 = XLSX.utils.json_to_sheet(dataTable[0].formDataLegend);
       const ws2 = XLSX.utils.json_to_sheet(dataTable[1].formDataLegend);
       const ws3 = XLSX.utils.json_to_sheet(dataTable[2].formDataLegend);
       const wp1 = XLSX.utils.json_to_sheet(dataTable[0].formDataPie);
       const wp2 = XLSX.utils.json_to_sheet(dataTable[1].formDataPie);
       const wp3 = XLSX.utils.json_to_sheet(dataTable[2].formDataPie);
       const wo1 = XLSX.utils.json_to_sheet(dataTable[0].formDataOpportunities);
       const wo2 = XLSX.utils.json_to_sheet(dataTable[1].formDataOpportunities);
       const wo3 = XLSX.utils.json_to_sheet(dataTable[2].formDataOpportunities);
       const wb = {
         Sheets: {
           'Legenda fabricante 1': ws1,
           'Legenda fabricante 2': ws2,
           'Legenda fabricante 3': ws3,
           'Gráfico de pizza fab. 1': wp1,
           'Gráfico de pizza fab. 2': wp2,
           'Gráfico de pizza fab. 3': wp3,
           'Oportunidades fabricante 1': wo1,
           'Oportunidades fabricante 2': wo2,
           'Oportunidades fabricante 3': wo3,
         },
         SheetNames: [
           'Legenda fabricante 1',
           'Legenda fabricante 2',
           'Legenda fabricante 3',
           'Gráfico de pizza fab. 1',
           'Gráfico de pizza fab. 2',
           'Gráfico de pizza fab. 3',
           'Oportunidades fabricante 1',
           'Oportunidades fabricante 2',
           'Oportunidades fabricante 3',
         ]
       };
       const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
       const data = new Blob([excelBuffer], { type: fileType });
       FileSaver.saveAs(data, "Dashboard fabricantes" + ".xlsx");
     } else if (dataTable.length === 2) {
       const ws1 = XLSX.utils.json_to_sheet(dataTable[0].formDataLegend);
       const ws2 = XLSX.utils.json_to_sheet(dataTable[1].formDataLegend);
       const wp1 = XLSX.utils.json_to_sheet(dataTable[0].formDataPie);
       const wp2 = XLSX.utils.json_to_sheet(dataTable[1].formDataPie);
       const wo1 = XLSX.utils.json_to_sheet(dataTable[0].formDataOpportunities);
       const wo2 = XLSX.utils.json_to_sheet(dataTable[1].formDataOpportunities);
       const wb = {
         Sheets: {
           'Legenda fabricante 1': ws1,
           'Legenda fabricante 2': ws2,
           'Gráfico de pizza fab. 1': wp1,
           'Gráfico de pizza fab. 2': wp2,
           'Oportunidades fabricante 1': wo1,
           'Oportunidades fabricante 2': wo2,
         },
         SheetNames: [
           'Legenda fabricante 1',
           'Legenda fabricante 2',
           'Gráfico de pizza fab. 1',
           'Gráfico de pizza fab. 2',
           'Oportunidades fabricante 1',
           'Oportunidades fabricante 2',
         ]
       };
       const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
       const data = new Blob([excelBuffer], { type: fileType });
       FileSaver.saveAs(data, "Dashboard fabricantes" + ".xlsx");
     } else if (dataTable.length === 1) {
       const ws1 = XLSX.utils.json_to_sheet(dataTable[0].formDataLegend);
       const wp1 = XLSX.utils.json_to_sheet(dataTable[0].formDataPie);
       const wo1 = XLSX.utils.json_to_sheet(dataTable[0].formDataOpportunities);
       const wb = {
         Sheets: {
           'Legenda': ws1,
           'Gráfico de pizza': wp1,
           'Oportunidades': wo1,
         },
         SheetNames: [
           'Legenda',
           'Gráfico de pizza',
           'Oportunidades',
         ]
       };
       const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
       const data = new Blob([excelBuffer], { type: fileType });
       FileSaver.saveAs(data, "Dashboard fabricantes" + ".xlsx");
     }
   } */

  return (
    <Card>
      <Container fluid className="mx-auto pt-3">
        <Row className="p-0">
          <Col style={{ maxWidth: "100%" }}>
            <InputGroup className="container-date">
            <DropdownButton
                className="container-select-type"
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={originFetch}
              >
                {originList.map(obj => {
                  return(<Dropdown.Item onBlur={(e) => { setOriginFetch(obj) }}>{obj}</Dropdown.Item>)
                })}
              </DropdownButton>
              <DropdownButton
                className="container-select-type"
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={regexData.name}
              >
                {props.regex.filter(probe => probe.subFilter === false).map(obj => {
                  return(<Dropdown.Item onBlur={(e) => { setRegexData(obj) }}>{obj.name}</Dropdown.Item>)
                })}
              </DropdownButton>
              <DropdownButton
                className="container-select-type"
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={formSearch}
              >
                <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Data Fim</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Mês</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Trimestre</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Semestre</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Ano</Dropdown.Item>
              </DropdownButton>
              <SearchInput
                id="input-opo"
                title="Data"
                value={dateFrom}
                selector="dataStart"
                onChange={(e) => { setDateFrom(e.target.value) }}
                show={formSearch === "Data"}
              />

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={mesSelecionado}
                className={formSearch === "Mês" ? "type-month" : "display-none"}
              >
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Janeiro</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Fevereiro</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Março</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Abril</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Maio</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Junho</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Julho</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Agosto</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Setembro</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Outubro</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Novembro</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Dezembro</Dropdown.Item>
              </DropdownButton>

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={anoSelecionado}
                className={formSearch === "Mês" ? "type-month" : "display-none"}
              >
                {year.map((o) => {
                  return <Dropdown.Item onBlur={(e) => { setAnoSelecionado(e.target.text) }}>{o}</Dropdown.Item>
                })}
              </DropdownButton>

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={trimestreSelecionado}
                className={formSearch === "Trimestre" ? "type-quarter" : "display-none"}
              >
                <Dropdown.Item onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>1º Trimestre</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>2º Trimestre</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>3º Trimestre</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>4º Trimestre</Dropdown.Item>
              </DropdownButton>

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={anoSelecionado}
                className={formSearch === "Trimestre" ? "type-quarter" : "display-none"}
              >
                {year.map((o) => {
                  return <Dropdown.Item onBlur={(e) => { setAnoSelecionado(e.target.text) }}>{o}</Dropdown.Item>
                })}
              </DropdownButton>

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={semestreSelecionado}
                className={formSearch === "Semestre" ? "type-semester" : "display-none"}
              >
                <Dropdown.Item onBlur={(e) => { setSemestreSelecionado(e.target.text) }}>1º Semestre</Dropdown.Item>
                <Dropdown.Item onBlur={(e) => { setSemestreSelecionado(e.target.text) }}>2º Semestre</Dropdown.Item>
              </DropdownButton>

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={anoSelecionado}
                className={formSearch === "Semestre" ? "type-semester" : "display-none"}
              >
                {year.map((o) => {
                  return <Dropdown.Item onBlur={(e) => { setAnoSelecionado(e.target.text) }}>{o}</Dropdown.Item>
                })}
              </DropdownButton>

              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={anoSelecionado}
                className={formSearch === "Ano" ? "type-year" : "display-none"}
              >
                {year.map((o) => {
                  return <Dropdown.Item onBlur={(e) => { setAnoSelecionado(e.target.text) }}>{o}</Dropdown.Item>
                })}
              </DropdownButton>

              <FormControl
                placeholder="De"
                aria-label="De"
                type="date"
                aria-describedby="basic-addon1"
                value={dateFrom}
                onChange={(e) => { setDateFrom(e.target.value) }}
                className={(formSearch === "Data Início" || formSearch === "Data Fim") ? "type-date" : "display-none"}
              />
              <FormControl
                placeholder="Até"
                aria-label="Até"
                type="date"
                aria-describedby="basic-addon1"
                value={dateTo}
                onChange={(e) => { setDateTo(e.target.value) }}
                className={(formSearch === "Data Início" || formSearch === "Data Fim") ? "type-date" : "display-none"}
              />
              <InputGroup.Append>
                <Button variant="flat" onClick={async () => handleGetDashboard()}><FontAwesomeIcon size="lg" icon={faSearch} /></Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          
        </Row>

        <Container fluid>
          <h4> Palavras-Chave: </h4>
          <div className="content-select">
            <Col lg="6" className="col-select">
              <Select
                options={options}
                isMulti
                value={listManufacturer}
                isDisabled={(options?.length || dashboard) === 0}
                onChange={(e) => setListManufacturer(e)}
                className="select"
                placeholder={"Selecione as palavras-chave"}
              />
            </Col>
            <Button className={statusButtonManufacturers === false ? "display-none" : "button-select mr-4"} onClick={startInterface} variant="flat">
              <FontAwesomeIcon size="lg" icon={faSearch} />
            </Button>
            <Button className={statusButtonManufacturers === false ? "display-none" : "button-select"} onClick={topManufacturers} variant="flat">
              {/* TOP Fabricantes */}
              <FontAwesomeIcon size="lg" icon={faChartArea} />
            </Button>
          </div>
        </Container>


        <Container>{(loading === true) ? <div className="lds-dual-ring m-4"></div> : <></>}</Container>

        <div className="container-chart">
          {/* DESENVOLVER VISUALIZAÇÃO DA PLANILHA NO LUGAR DA PIZZA */}


          {dataPie.map((chart) => {
            console.log(dataPie)
            if (chart.message) {
              return (
                <Col className="legenda-pie-chart">
                  <h6>{chart.message}{chart.title}</h6>
                </Col>
              )
            } else {
              return (
                null
              )
            }
          })}
        </div>
        <div className="container-chart">
          {legendManufacturer.map((legend) => {
            if (legend.presented === 0 && legend.declined === 0 && legend.noReply === 0 && legend.totalVolumeInReal === 0) {
              return (
                <Col className={statusPieChart === true ? "legenda-pie-chart" : "display-none"}>
                  <h6><button type="button" onClick={() => downloadTimeseriesManufacturer(legend?.name)} className="btn btn-primary">Baixar indicadores</button></h6>
                </Col>
              )
            } else {
              return (
                <Col
                  className={
                    statusPieChart === true
                      ? "legenda-pie-chart"
                      : "display-none"
                  }
                >
                  <h3>{legend?.name}</h3>
                  <h6>Cotações apresentadas: {legend?.presented}</h6>
                  <h6>Cotações declinadas: {legend?.declined}</h6>
                  <h6>Cotações sem resposta: {legend?.noReply}</h6>
                  <h6>
                    Total:{" "}
                    {legend?.presented + legend?.declined + legend?.noReply}
                  </h6>

                  {/* <h6>Volume total em real: {legend?.totalVolumeInReal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h6> */}
                  <h6>
                    <button
                      type="button"
                      onClick={() =>
                        downloadTimeseriesManufacturer(legend?.name)
                      }
                      className="btn btn-primary"
                    >
                      Baixar indicadores
                    </button>
                  </h6>
                </Col>
              );
            }
          })}
        </div>

        <Container>{(loadingSelectMultiManufacturer === true) ? <div className="lds-dual-ring m-4"></div> : <></>}</Container>

        <Row className={statusSelectSpecificMultiManufacturer === false ? "display-none" : "ml-4 mt-3"}>
          <h5> Analise do item especifico por fabricantes: </h5>
          <select onChange={(e) => { setSpecificItemSelected(e.target.value) }} value={specificItemSelected} className="p-0 w-30 ml-2">
            <option className={specificItemSelected !== "" ? "display-none" : ""}>Selecione o item</option>
            {dataSelectSpecificComparison.map(item => {
              return (<option value={item}>{item}</option>)
            })}
          </select>

          <Select
            options={optionsSpecific}
            isMulti
            value={specificManufacturerSelected}
            defaultValue={[]}
            isDisabled={options?.length === 0}
            onChange={(e) => setSpecificManufacturerSelected(e)}
            className="p-0 w-30 ml-2"
          />
          <Button onClick={() => { specificSelect(); setDataBar([]) }} className="ml-2" variant="flat"><FontAwesomeIcon size="lg" icon={faSearch} /></Button>
        </Row>

        <Container>{(loadingSelectSpecificMultiManufacturer === true) ? <div className="lds-dual-ring m-4"></div> : <></>}</Container>

        <Container fluid className={statusSelectSpecific === false ? "display-none" : ""}>
          <h4> Item: </h4>
          <select onChange={(e) => { selectItem(e) }} value={itemSelecionado} className="p-2 pl-4 pr-4 w-50">
            <option className={itemSelecionado !== "" ? "display-none" : ""}>Selecione a especificação do item</option>
            {dataSelectSpecific.map(item => {
              return (<option value={item}>{item}</option>)
            })}
          </select>
        </Container>

        <Container className={statusFirstCharts === false ? "display-none" : ""}>
          <Row>
            <Col>
              <RequestBarChart
                className={dataBar.length === 0 ? "display-none" : "row d-flex justify-content-center"}
                data={dataBar.map((data) => { return data.data })}
                categories={dataBar.map((categories) => { return categories.categories })}
                name="Quantidade"
                type="bar"
                title={dataBar[0]?.title}
                height={300}
                dataLabels={true}
                width="100%"
              />
            </Col>
            {dataLine.map((data) => {
              return (
                <Col>
                  <RequestLineChart
                    className="row d-flex justify-content-center"
                    data={data.quantity}
                    categories={data.description}
                    type="line"
                    height={300}
                    width="100%"
                    title='Análise temporal da ocorrência do item durante o ano'
                  />
                </Col>
              )
            })}
            <Col className={statusChartMultiLine === false ? "display-none" : ""}>
              <RequestThreeLineChart
                className="row d-flex justify-content-center"
                data={dataMultiLine}
                categories={["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]}
                type="line"
                height={300}
                width="100%"
                title="Oportunidades para o item ao decorrer dos meses"
              />
            </Col>
          </Row>
        </Container>

        <Container className={statusSelectOpportunity === false ? "display-none" : ""}>
          <h5>Escolha a oportunidade que deseja analisar</h5>
          <select onChange={(e) => { selectOpportunity(e) }} value={oportunidadeSelecionada} className="p-2 pl-4 pr-4 w-50">
            <option className={oportunidadeSelecionada !== "" ? "display-none" : ""}>Selecione uma oportunidade</option>
            {opportunities.map(item => {
              return (<option value={item}>{item}</option>)
            })}
          </select>

          {statusFirstCharts === true && statusSelectOpportunity === true && statusBarAndLineChart === true ?
            dataBarAndLine?.map((data) => {
              return (
                <RequestBarAndLineChart
                  className={dataBarAndLine.length === 0 ? "display-none" : "row d-flex justify-content-center"}
                  dataCompany={data?.dataCompany}
                  dataConcurrent={data?.dataConcurrent}
                  nameCompany={currentCompanyName}
                  labels={data?.labels}
                  type="line"
                  height="450"
                  width="800"
                  id="line-and-column"
                />
              )
            })
            :
            <></>
          }
          <Row className={statusFirstCharts === true && statusSelectOpportunity === true && statusBarAndLineChart === true ? "data-empresa" : "display-none"}>
            <h6>Valor cotado pela {currentCompanyName}: R${quotationValueEmpresa}</h6>
          </Row>
        </Container>
        {/* <Container className={statusFirstCharts === false ? "display-none" : ""} fluid>
          <Button variant="flat" onClick={(e) => downloadTable(e)}>
            <FontAwesomeIcon size="lg" icon={faDownload} />
          </Button>
        </Container> */}

        <ModalDownload
          showModalDownload={showModalDownload}
          onHide={() => setShowModalDownload(false)}
          opportunityManufacturer={opportunityManufacturer}
          initProposalData={props.initProposalData}
          user={props.user}
          currentCompany={currentCompany}
        />
      </Container>
    </Card >
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDashboard: (userId, companyId, dateFrom, dateTo, originFetch) => dispatch(storeActions.setDashboard(userId, companyId, dateFrom, dateTo, originFetch)),
    initOpportunities: () => dispatch(storeActions.initOpportunities()),
    initPortals: (token) => dispatch(storeActions.initPortals(token)),
    fetchData: (token) => dispatch(storeActions.fetchData(token)),
    setUserInteraction: (userId, companyId, opportunityId) => dispatch(storeActions.setUserInteraction(userId, companyId, opportunityId)),
    setPastOpportunities: (userId, companyId, dateFrom, dateTo) => dispatch(storeActions.setPastOpportunities(userId, companyId, dateFrom, dateTo)),
    initRegex: () => dispatch(storeActions.initRegex()),
    initProposalData: (usuario, index) => dispatch(storeActions.initProposalData(usuario, index)),
    notify: (notification, delay) =>
      dispatch(storeActions.notify(notification, delay)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    opportunities: state.opportunities.opportunities,
    error: state.opportunities.error,
    portals: state.portals.portals,
    items: state.items.items,
    proposals: state.proposals.proposals,
    regex: state.regex.regex,
    companies: state.companies.companies
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Fabricantes);