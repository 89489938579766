import React, { useState } from 'react';
import { Card, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./DataFilter.scss";

export default function DataFilter(props) {
  const { user, regexFilter, handleResetFilter, setArrOppsItems, setArrOpps, rejectableOpps, setRejectableOpps, setArrOppsWarn, param, regexBTN, setRegexBTN, setCategorySelected, setMainManufacturer, originSelected, setShowRejectable } = props

  const [statusBox, setStatusBox] = useState({
    "0": true,
    "1": true,
    "2": true,
    "3": true,
  });
  const [listOpFiltered, setListOpFiltered] = useState([])
  const [rawOpps, setRawOpps] = useState([])
  const [originSelectedView, setOriginSelectedView] = useState()
  const [finished, setFinished] = useState(false)

  let objToTemplate = []

  try {
    function componentToHex(c) {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    }

    function rgbToHex(r, g, b) {
      return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    function hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      // return result ? {
      //   r: parseInt(result[1], 16),
      //   g: parseInt(result[2], 16),
      //   b: parseInt(result[3], 16)
      // } : null;

      return result ? parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) : null;
    }

    let objRegex = []

    if (regexFilter.length <= 0) {
      return (<></>)
    }


    objRegex = regexFilter.filter(o => o.name !== "Fabricante")
    if (regexFilter.some(o => o.name === "Fabricante")) {
      objRegex.unshift(regexFilter.find(o => o.name === "Fabricante"))
    }


    let listFilter = {}
    let rejectable = [];
    let oppsPassed = [];
    let d = new Date();

    function keywordProbe(opportunities, catalog) {
      const catalogs = catalog.filter(probe => probe.subFilter === false && probe.disabled === false);
      let output = {}

      opportunities.filter(probe => probe.origin === originSelected).map(opportunity => {
        output[opportunity.index] = {}
        const objItems = props.param.items.filter(probe => probe.opportunityId._id === opportunity._id);
        catalogs.map(oneCatalog => {
          output[opportunity.index][oneCatalog.name] = {
            "warnings": [],
            "keywords": [],
            "important": false
          }

          oneCatalog.category.map(category => {
            const exceptions = !!category?.exception ? category.exception : { "description": [], "item": [] };
            const words = category.regex;

            if (category.target === "item") {
              if (!exceptions.description.some(test => opportunity.description.toLowerCase().includes(test.toLowerCase()))) {
                words.map(word => {
                  if (objItems.some(probe => (probe.partNumber.toLowerCase().includes(word.toLowerCase()) || probe.fullDescription.toLowerCase().includes(word.toLowerCase())))) {
                    if (!output[opportunity.index][oneCatalog.name]["important"]) {
                      output[opportunity.index][oneCatalog.name]["important"] = category.important;
                    }

                    output[opportunity.index][oneCatalog.name]["keywords"].push(word);
                    let dateProbe = new Date(opportunity.dateEnd * 1000);
                    dateProbe.setDate(dateProbe.getDate() - 3);
                    if (dateProbe <= d) {
                      if (!output[opportunity.index][oneCatalog.name]["warnings"].includes(opportunity.index)) {
                        output[opportunity.index][oneCatalog.name]["warnings"].push(opportunity.index);
                      }
                    }
                  }
                })
              }
            } else {
              if (!exceptions.description.some(test => opportunity.description.toLowerCase().includes(test.toLowerCase()))) {
                words.map(word => {
                  if (opportunity.description.toLowerCase().includes(word.toLowerCase())) {
                    if (!output[opportunity.index][oneCatalog.name]["important"]) {
                      output[opportunity.index][oneCatalog.name]["important"] = category.important;
                    }
                    output[opportunity.index][oneCatalog.name]["keywords"].push(word);
                    let dateProbe = new Date(opportunity.dateEnd * 1000);
                    dateProbe.setDate(dateProbe.getDate() - 3);
                    if (dateProbe <= d) {
                      if (!output[opportunity.index][oneCatalog.name]["warnings"].includes(opportunity.index)) {
                        output[opportunity.index][oneCatalog.name]["warnings"].push(opportunity.index);
                      }
                    }
                  }
                })
              }
            }
          })
        })
      })

      let filtered = {}

      Object.entries(output).map(list1 => {
        const index = list1[0]
        const indexData = list1[1]

        Object.entries(indexData).map(list2 => {
          const originName = list2[0]
          const originData = list2[1]
          let important = false;
          // matches: [[keywords], [index]]

          filtered[originName] = {
            "matches": [[[], []]]
          }

          // Só entra nos filtros oportunidades com palavras-chave
          if (originData.keywords.length > 0) {
            let selector = 0;
            // Primeiros dados
            console.log(filtered[originName]["matches"][selector])
            if (filtered[originName]["matches"][selector][0].length === 0) {
              originData.keywords.map(keyword => {
                filtered[originName]["matches"][selector][0].push(keyword)
              })
              filtered[originName]["matches"][selector][1].push(index)

            } else {
              // A palavra-chave deverá existir na 
              // variável de controle para ter a oportunidade agregada

              console.log(filtered[originName]["matches"][selector])
              filtered[originName]["matches"].map(loop => {
                // console.log(filtered[originName]["matches"][selector][0], originData.keywords)
                if (!filtered[originName]["matches"][selector][0].some(word => originData.keywords.includes(word))) {
                  originData.keywords.map(keyword => {
                    if (!filtered[originName]["matches"][selector][0].includes(keyword)) {
                      filtered[originName]["matches"][selector][0].push(keyword)
                    }
                  })
                  filtered[originName]["matches"][selector][1].push(index)
                } else {
                  selector++;
                }
              })
            }
          }
        })
      })

      return filtered;
    }

    // console.log(keywordProbe(props.param.opportunities, objRegex))

    // console.log(regexFilter, objRegex)

    // props.param.opportunities.length !== rawOpps.length
    if (finished === false && listOpFiltered.length === 0 && props.param.opportunities.length > 0 || originSelectedView !== originSelected) {
      console.log("rendering...")
      props.param.opportunities.map(objOpps => {
        const objItems = props.param.items.filter(probe => probe.opportunityId._id === objOpps._id); //probe.description == "Item de controle LiBO.Di" && 
        // console.log(objOpps.index, objItems.length)
        // const subFilter = objRegex.find(probe => probe.subFilter === true);
        // console.log(objOpps.index)
        if (objOpps.origin === originSelected) {

          objRegex.map(objFilter => {
            if (objFilter.subFilter === false && objFilter.disabled === false) {
              if (listFilter[objFilter.name] === undefined) {
                listFilter[objFilter.name] = {};
              }

              objFilter.category.filter(probe => !probe["userView"] || probe["userView"]?.length === 0 || probe["userView"]?.some(oneUser => oneUser["value"] === user._id)).map(categoryItem => {
                if (listFilter[objFilter.name][categoryItem["name"]] === undefined) {
                  listFilter[objFilter.name][categoryItem["name"]] = {
                    "ctOpportunities": [],
                    "ctWarnings": [],
                    "ctItems": [], //Ícone amarelo **DESATIVADO**
                    "keywords": [], //Legenda de itens do fabricante
                    "ctOpItems": [] //Ícone amarelo **DESATIVADO**
                  }
                }

                const exceptions = !!categoryItem?.exception ? categoryItem.exception : { "description": [], "item": [] };
                // console.log(exceptions)
                let words = categoryItem.regex

                if (categoryItem.target === "item") {
                  words.map(word => {
                    //Testa se o titulo da oportunidade tem contra-filtro
                    // Contra-filtro de itens suspensa para evitar falsos negativos
                    //!exceptions.item.some(test => objItems.some(test2 => test2.fullDescription.includes(test))) || 
                    if (!exceptions.description.some(test => objOpps.description.toLowerCase().includes(test.toLowerCase()))) {
                      if (objItems.some(probe => (probe.partNumber.toLowerCase().includes(word.toLowerCase()) || probe.fullDescription.toLowerCase().includes(word.toLowerCase())))) {

                        if (!oppsPassed.includes(objOpps.index)) {
                          oppsPassed.push(objOpps.index);
                        }
                        //Legenda de itens do fabricante 
                        if (!listFilter[objFilter.name][categoryItem["name"]]["keywords"].includes(word)) {
                          listFilter[objFilter.name][categoryItem["name"]]["keywords"].push(word);
                          listFilter[objFilter.name][categoryItem["name"]]["important"] = !!listFilter[objFilter.name][categoryItem["name"]]["important"] ? true : !!categoryItem["important"];
                          listFilter[objFilter.name][categoryItem["name"]]["importantColor"] = !!categoryItem["importantColor"] ? hexToRgb(categoryItem["importantColor"]) : '254, 200, 48';
                        }
                        if (!listFilter[objFilter.name][categoryItem["name"]]["ctOpportunities"].includes(objOpps.index)) {
                          listFilter[objFilter.name][categoryItem["name"]]["ctOpportunities"].push(objOpps.index);
                          let dateProbe = new Date(objOpps.dateEnd * 1000);
                          dateProbe.setDate(dateProbe.getDate() - 3);
                          if (dateProbe <= d) {
                            if (!listFilter[objFilter.name][categoryItem["name"]]["ctWarnings"].includes(objOpps.index)) {
                              listFilter[objFilter.name][categoryItem["name"]]["ctWarnings"].push(objOpps.index);
                            }
                          }
                        }
                      }
                    }
                  })
                } else {
                  words.map(word => {
                    if (objOpps.description.toLowerCase().includes(word.toLowerCase())) {
                      if (!oppsPassed.includes(objOpps.index)) {
                        oppsPassed.push(objOpps.index);
                      }
                      //  Ação referente ao ícone amarelo **DESATIVADO**
                      if (!listFilter[objFilter.name][categoryItem["name"]]["keywords"].includes(word)) {
                        listFilter[objFilter.name][categoryItem["name"]]["keywords"].push(word);
                        listFilter[objFilter.name][categoryItem["name"]]["important"] = !!listFilter[objFilter.name][categoryItem["name"]]["important"] ? true : !!categoryItem["important"];
                      }
                      if (!listFilter[objFilter.name][categoryItem["name"]]["ctOpportunities"].includes(objOpps.index)) {
                        listFilter[objFilter.name][categoryItem["name"]]["ctOpportunities"].push(objOpps.index);
                        let dateProbe = new Date(objOpps.dateEnd * 1000);
                        dateProbe.setDate(dateProbe.getDate() - 3);
                        if (dateProbe <= d) {
                          if (!listFilter[objFilter.name][categoryItem["name"]]["ctWarnings"].includes(objOpps.index)) {
                            listFilter[objFilter.name][categoryItem["name"]]["ctWarnings"].push(objOpps.index);
                          }
                        }
                      }
                    }
                  })
                }
              })
            }
          })
        }
      });

      rejectable = props.param.opportunities.filter(probe => !oppsPassed.includes(probe.index) && probe.origin === originSelected);
      if (rejectable.length !== rejectableOpps.length) {
        setRejectableOpps(rejectable);
      }
      setListOpFiltered(listFilter)
      // setRawOpps(props.param.opportunities)
      setOriginSelectedView(originSelected)

      setFinished(true)
    }


    objToTemplate = Object.entries(listOpFiltered);
  } catch (err) {
    console.log("Something happened...")
    // console.log(err)
  }

  return (
    <div className="div-groups">
      {objToTemplate.map((item, index) => {
        return (
          <Card className="p-2 ml-2 mr-2 mt-2 mb-2 card-groups"
            key={item[0]}
          >
            <Row className="d-flex p-0 m-0">
              <h5>{item[0]}</h5>
            </Row>
            <div id={index} className={statusBox[index] === true ? "container-filter" : "display-none"} >
              {Object.entries(item[1]).map(category => {
                if (category[1]["ctOpportunities"].length > 0) {
                  return (
                    <Button
                      key={category[0]}
                      size="sm"
                      variant="items"
                      style={category[1]["important"] ? {
                        border: '1px solid #1367ec',
                        backgroundColor: '#1367ec',
                        color: "#fff"
                      } : {
                        color: "#555555",
                        border: "1px solid #555555"
                      }}
                      className={(category[1]["important"] === true) ? "button-box btn-priority" : "button-box btn-items"}
                      onClick={() => {
                        localStorage.setItem("selectedPage", 0)
                        setRegexBTN(category[1]["keywords"].join(","));
                        setArrOppsItems(category[1]["ctOpItems"]);
                        setArrOppsWarn(category[1]["ctWarnings"]);
                        setArrOpps(category[1]["ctOpportunities"]);
                        setMainManufacturer(category[0]);
                        setCategorySelected(item[0]);
                        setShowRejectable(false);
                      }}
                    >
                      <div className="data-button">
                        <div className="mr-1">
                          <span className="badge badge-primary legend-button">{category[1]["ctOpportunities"].length}</span>
                          <span className={(category[1]["ctWarnings"].length === 0) ? "display-none" : "badge badge-danger legend-button oportunidade-vencer"}>{category[1]["ctWarnings"].length}</span>
                          <span>{category[0]}</span>
                        </div>
                      </div>
                      {/* Legenda de itens para cada fabricante **DESATIVADO** */}
                      {/* <ListGroup.Item className={(category[0] === category[1]["keywords"][0] || category[1]["keywords"][0] === "Umbilica") ? "display-none" : ""}>
                      <small>{category[1]["keywords"].join(", ")}</small>
                    </ListGroup.Item> */}
                    </Button>
                  )
                } else {
                  return (
                    <>
                    </>
                  )
                }
              })}
            </div>
          </Card>
        )
      })}
    </div>
  )
}